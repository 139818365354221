// dashbaord
import Default from "../Components/Dashboard/Default";
import Logout from '../Auth/Signout';


import Typography from "../Components/UiKits/Typography";
import Avatars from "../Components/UiKits/Avtars";
import HelperClasses from "../Components/UiKits/HelperClass";
import Grid from "../Components/UiKits/Grid";
import TagAndPills from "../Components/UiKits/TagAndPills";
import Progressbars from "../Components/UiKits/Progress";
import Modals from "../Components/UiKits/Modals";
import Alert from "../Components/UiKits/Alert";
import Popover from "../Components/UiKits/Popover";
import Tooltips from "../Components/UiKits/Tooltip";
import Spinners from "../Components/UiKits/Spinners";
import Dropdowns from "../Components/UiKits/Dropdown";
import Accordian from "../Components/UiKits/Accordian";
import TabBootstrap from "../Components/UiKits/Tabs/BootstrapTab";
import TabLine from "../Components/UiKits/Tabs/LineTab";
import Navs from "../Components/UiKits/Navs";
import Shadow from "../Components/UiKits/Shadows";
import List from "../Components/UiKits/Lists";

// //Bonus-ui
import Scrollable from "../Components/Bonus-Ui/Scrollable";
import SweetAlerts from "../Components/Bonus-Ui/SweetAlert";
import Carousels from "../Components/Bonus-Ui/Carousel";
import Paginations from "../Components/Bonus-Ui/Pagination";
import Steps from "../Components/Bonus-Ui/Steps";
import BreadcrumbClass from "../Components/Bonus-Ui/Breadcrumb";
import BasicCards from "../Components/Bonus-Ui/Cards/BasicCards/index";
import CreativeCards from "../Components/Bonus-Ui/Cards/CreativeCards";
import TabCard from "../Components/Bonus-Ui/Cards/TabCard";

import Usuarios from "../Components/Usuario";
import Categorias from "../Components/Categoria";
import Produtos from "../Components/Produto";
import EstruturaProdutos from "../Components/EstruturaProduto";

import WizardPedido from "../Components/Pedido";
import PedidoRetaguarda from "../Components/PedidoRetaguarda";

// //Pages
import SamplePage from "../Components/Pages/Sample";

export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard/default/:layout`, Component: <Default /> },

  { path: `${process.env.PUBLIC_URL}/login/:layout`, Component: <Logout /> },  

  { path: `${process.env.PUBLIC_URL}/ui-kits/typography/:layout`, Component: <Typography /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/avatar/:layout`, Component: <Avatars /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/helper-class/:layout`, Component: <HelperClasses /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/grids/:layout`, Component: <Grid /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/tag-pills/:layout`, Component: <TagAndPills /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/progress/:layout`, Component: <Progressbars /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/modal/:layout`, Component: <Modals /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/alert/:layout`, Component: <Alert /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/popover/:layout`, Component: <Popover /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/tooltips/:layout`, Component: <Tooltips /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/spinner/:layout`, Component: <Spinners /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/dropdown/:layout`, Component: <Dropdowns /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/accordion/:layout`, Component: <Accordian /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/tabs/bootstrap/:layout`, Component: <TabBootstrap /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/tabs/line/:layout`, Component: <TabLine /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/navs/:layout`, Component: <Navs /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/shadow/:layout`, Component: <Shadow /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/list/:layout`, Component: <List /> },

  { path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable/:layout`, Component: <Scrollable /> },
      { path: `${process.env.PUBLIC_URL}/bonus-ui/sweet-alert/:layout`, Component: <SweetAlerts /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/carousel/:layout`, Component: <Carousels /> },
    { path: `${process.env.PUBLIC_URL}/bonus-ui/pagination/:layout`, Component: <Paginations /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/steps/:layout`, Component: <Steps /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb/:layout`, Component: <BreadcrumbClass /> },
      { path: `${process.env.PUBLIC_URL}/bonus-ui/card/basiccards/:layout`, Component: <BasicCards /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativecards/:layout`, Component: <CreativeCards /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabcard/:layout`, Component: <TabCard /> },

  // //page
  { path: `${process.env.PUBLIC_URL}/pages/sample-page/:layout`, Component: <SamplePage /> },


  { path: `${process.env.PUBLIC_URL}/app/usuario/:layout`, Component: <Usuarios /> },  
  { path: `${process.env.PUBLIC_URL}/app/categoria/:layout`, Component: <Categorias /> },    
  { path: `${process.env.PUBLIC_URL}/app/produto/:layout`, Component: <Produtos /> },      
  { path: `${process.env.PUBLIC_URL}/app/estrutura/:layout`, Component: <EstruturaProdutos /> },      
  { path: `${process.env.PUBLIC_URL}/app/pedido/:layout`, Component: <WizardPedido /> },
  { path: `${process.env.PUBLIC_URL}/app/receberpedido/:layout`, Component: <PedidoRetaguarda /> },  
  
];
