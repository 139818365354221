import React, { Fragment, useState, useContext, useEffect, useCallback} from "react";
import { useForm } from "react-hook-form";
import { Label, Form, FormGroup, Card, CardBody, CardHeader, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { H3, H1,  H5, Btn } from '../../AbstractElements';
import CategoriaAppContext from '../../_helper/Categoria';
import ProdutoAppContext from '../../_helper/Produto';
import PedidoAppContext from '../../_helper/Pedido';
import Commen from "./Commen";
import SubTotal from "./SubTotal";
import Scrollbars from 'react-custom-scrollbars-2';
import CommonModal from '../../Components/UiKits/Modals/common/modal';
import SweetAlert from 'sweetalert2';

const MenuPedido = ({ setSteps, setFormdata, formdata, pedido }) => {
  const {  getCategoriasMenu,getCategoriasTipoUso, categorias, categoriasMenu  } = useContext(CategoriaAppContext);         
  const [LeftLineTab, setLeftLineTab] = useState('1');  
  const [val, setVal] = useState(false);
  const [valorTotalPedido, setValorTotalPedido] = useState(0.00);  
  const {listProdutoPedido, getListProdutoPedido} = useContext(ProdutoAppContext);         
  const [modal, setModal] = useState(false);
  const { createPedido, pedidoData } = useContext(PedidoAppContext);      

   const toggle = (e) => {

    setModal(!modal);

    if (e.target.id === 'btnModalConfirm'){
      pedido = {nomeCliente: '', valorTotal: 0.00, itens: []};
      window.location.reload();
    }

  }
  const {
    handleSubmit,
  } = useForm();
  const onSubmit = async (data) => {

    if (val === false)
      {
        SweetAlert.fire({ title: '', text: 'Selecione um ou mais produtos para Continuar ou clique em Cancelar.', icon: 'error' });        
        return;
      }

      pedido.itens = [];

      let i = 0;
      listProdutoPedido.map((prd, ix) => {
        if (prd.quantidadePedido > 0) {

          i++;

          let item = [];

            item.id = 0;
            item.idPedido = 0;
            item.numeroItem = ix;
            item.idProduto = prd.id;
            item.tamanho = prd.tamanho;
            item.quantidade = prd.quantidadePedido;

            if (prd.tamanho === "p") {
              item.valorUnitario = prd.precoPequeno;
            }

            if (prd.tamanho === "m") {
              item.valorUnitario = prd.precoMedio;
            }            

            if (prd.tamanho === "g") {
              item.valorUnitario = prd.precoGrande;
            }                        

            item.valorTotal = prd.valorTotal;

            let ingPrd = [];

            if ((prd.categoria.tipoUso === "C" || prd.categoria.tipoUso === "F") && prd.ingredientes !== "" && prd.ingredientes !== undefined && prd.ingredientes.length > 0)
              {
                prd.ingredientes.map(function(ing) {  
                  if(ing.selecionado)
                    {
                      let valorIng = 0;

                      if (prd.tamanho === "p"){
                        valorIng = (prd.quantidadePedido * ing.precoPequeno);
                      }
                
                      if (prd.tamanho === "m"){
                        valorIng = (prd.quantidadePedido * ing.precoMedio);
                      }
                      
                      if (prd.tamanho === "g"){
                        valorIng = (prd.quantidadePedido * ing.precoGrande);
                      }   

                      var objIng = {
                        "id" : 0,
                        "idItemPedido": 0,
                        "idComponente": ing.id,
                        "valorUnitario": valorIng,
                        "tamanho" : prd.tamanho
                        };

                      /*
                      var objIng = {
                        "id" : ing.id ,
                        "idCategoria": ing.idCategoria ,
                        "descricao": ing.descricao ,
                        "precoPequeno": ing.precoPequeno ,
                        "precoMedio": ing.precoMedio ,
                        "precoGrande": ing.precoGrande ,
                        "quantidadeEstoque": ing.quantidadeEstoque ,
                        "controlaSaldo": ing.controlaSaldo ,
                        "ativo": ing.ativo ,
                        "selecionado ": ing.selecionado ,
                        "quantidadePedido": ing.quantidadePedido ,
                        "valorTotal": ing.valorTotal ,
                        "categoria": ing.categoria ,
                        "observacao": ing.observacao ,
                        "tamanho" : ing.tamanho
                        };
                        */
                      ingPrd.push(objIng);
                    }
                });
                
                prd.ingredientes = ingPrd;

              }

                var obj = {
                "id" : 0,
                "idPedido"  : 0,
                "numeroItem" : i,
                "idProduto" : item.idProduto,              
                "tamanho" : item.tamanho,
                "quantidade" : item.quantidade,
                "valorUnitario" : item.valorUnitario,
                "valorTotal" : item.valorTotal,
                "composicoes" :  ingPrd
                };

            pedido.itens.push(obj);
          }
        });      

    await createPedido(JSON.stringify(pedido));
  };

  const atualizaValor = useCallback(state => {
    //if (val === false)
    //{
      //setValorTotalPedido(0.00);
    //}
}, [val]);  

const zeraValorTotalPedido = useCallback(state => {
    setValorTotalPedido(0.00);
}, [valorTotalPedido]);  

  useEffect(() => {
    getCategoriasMenu();
    getCategoriasTipoUso("P");
    getListProdutoPedido();

    if (pedido.itens !== undefined)
    {
        pedido.itens = listProdutoPedido;
    }

  },[]);  

  const setItemOpcional  = (obj) => {

    listProdutoPedido.map(function(prd) {
      if(prd.id === obj.id){
        let obs = '';

        prd.observacao = '';

        prd.ingredientes?.map(function(ing) {
          if (!ing.selecionado){
            obs = obs + "Sem " + ing.descricao + "|"
          }
          });

          if(obs !== '') {
            prd.observacao = "(" + obs + ")";
          }

          let element = document.getElementById('sp' + prd.id);    
          if (element != null){
            element.innerText  = prd.quantidadePedido + 'X' + prd.descricao + (prd.observacao !== undefined ? prd.observacao : '');
          }
      }
      });
  };   

  const handleQuantidade = ((idxPrd, quantidade, tamanho) => {
    setVal(false);
    zeraValorTotalPedido();
    let totalTmp = 0.00;   

    listProdutoPedido.map(function(prd) {
      if (prd.id === idxPrd) {

        let obs = '';            
        prd.observacao = '';            

      prd.quantidadePedido = quantidade;
      prd.tamanho = tamanho;      

      if (tamanho === "p"){
        prd.valorTotal = (prd.quantidadePedido * prd.precoPequeno);
      }

      if (tamanho === "m"){
        prd.valorTotal = (prd.quantidadePedido * prd.precoMedio);
      }
      
      if (tamanho === "g"){
        prd.valorTotal = (prd.quantidadePedido * prd.precoGrande);
      }    
      
      let valorIngrediente = 0.00;

      if (prd.categoria.tipoUso === "C" && prd.ingredientes !== "" && prd.ingredientes !== undefined && prd.ingredientes.length > 0)
        {
          prd.ingredientes.map(function(ing) {  
            
            if (ing.selecionado === true)
              {
                obs = obs + ing.categoria.descricao + ':' + ing.descricao + "|"

                if (tamanho === "p"){
                  valorIngrediente = valorIngrediente + (prd.quantidadePedido * ing.precoPequeno);
                }
      
                if (tamanho === "m"){
                  valorIngrediente = valorIngrediente + (prd.quantidadePedido * ing.precoMedio);
                }
                
                if (tamanho === "g"){
                  valorIngrediente = valorIngrediente + (prd.quantidadePedido * ing.precoGrande);
                }   
              }
          });

          prd.valorTotal = prd.valorTotal + valorIngrediente;              

          if(obs !== '') {
            prd.observacao = " - Ingredientes:" + obs;
          }

          let element = document.getElementById('sp' + prd.id);    
          if (element != null){
            element.innerText  = prd.quantidadePedido + 'X' + prd.descricao + (prd.observacao !== undefined ? prd.observacao : '');
          }          

          let elementGrp = document.getElementById('idgTotPrd' + prd.id);    
          if (elementGrp != null){
            elementGrp.innerText  = 'R$ ' + prd.valorTotal.toFixed(2);
          }          
        }           

      }

      if (prd.valorTotal > 0)
        {
          totalTmp = totalTmp + prd.valorTotal;
        }          

   });
    
    setValorTotalPedido(totalTmp);          

    atualizaValor();    

    if (totalTmp > 0) {
      setVal(true);
    }    

  });  

  return (
    <Fragment>
          <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
          <Card>      
        <CardHeader><H3>Selecione um Item para Iniciar o Pedido</H3></CardHeader>        
          <CardBody>                  
        <Row>
          <Row>
            <Col sm='2' className='tabs-responsive-side'>
              <Nav className='flex-column nav-pills border-tab nav-left'>
                {categoriasMenu?.map((categoria, idxCat) =>
                (categoria.ativo) &&
                  <NavItem key={'idNav' + categoria.id}>
                    <NavLink href='#javascript' className={LeftLineTab === categoria.id ? 'active' : ''} onClick={() => setLeftLineTab(categoria.id)}>
                      <h4>{categoria.descricao} </h4>
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </Col>
            <Col sm='10'>
              <TabContent activeTab={LeftLineTab}>
                {categoriasMenu.map((categoria, idxCat) =>
                  <TabPane className='fade show' tabId={categoria.id} key={'tabp' + categoria.id}>
                    <Scrollbars className="horizontal-scroll" style={{width: '100%',height:450}}>                  
                      <Row>
                        {listProdutoPedido.map((prd, idxPrd) =>  
                          (categoria.id === prd.idCategoria && prd.ativo === true) &&
                            <FormGroup key={'frmGr' + prd.id} className={categoria.tipoUso === 'F' || categoria.tipoUso === 'C' ? 'col-sm-12' : 'col-sm-5' }>
                              <Label><H5>{prd.descricao}</H5></Label>
                              {(categorias !== "" && categorias !== undefined && categorias.length !== 0) && 
                              <Commen id={'prd' + prd.id} val={prd.quantidadePedido} setVal={handleQuantidade} objData={prd} objCategoria={categoria} objCategorias={categorias} setOpcao={setItemOpcional} />
                              }
                            </FormGroup>                     

                          )}    
                      </Row>                      
                    </Scrollbars>                     
                  </TabPane>
                )}
              </TabContent>
            </Col>
          </Row>
        </Row>
        </CardBody>
      </Card>        
        <Row>
          {(val === true) && <SubTotal produtos={listProdutoPedido} valorTotal={valorTotalPedido} />}
        </Row>
        <center>        
          <Row>
            <Col lg="6">
            
              <FormGroup className='col-md-6'>
                <div className="text-center btn-mb">
                  <Btn attrBtn={{ color: "danger", className: "w-100 mt-2", onClick: (e) => toggle(e)}}><H1>Cancelar</H1></Btn>                
                  <CommonModal isOpen={modal} title='Confirmação' toggler={toggle} ><H5>Confirma o cancelamento do Pedido?</H5></CommonModal>                  
                </div>
              </FormGroup>
            </Col>                    
            <Col lg="6">
              <FormGroup className='col-md-6'>

                <Btn attrBtn={{ className: "w-100 mt-2", color: 'primary' }} type="submit"><H1>Finalizar Pedido</H1></Btn>

              </FormGroup>
            </Col>
          </Row>
          </center>
      </Form>      

    </Fragment>
  );
};

export default MenuPedido;
