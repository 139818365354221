import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Form, Label, FormGroup, Button } from "reactstrap";
import {  H4,H2,H1 } from '../../AbstractElements';

const OpcaoTamanhoPizza = ({ setSteps, setFormdata, formdata, pedido }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setFormdata((prev) => ({ ...prev, ...data }));
    setSteps((prev) => prev + 1);
  };

  return (
    <Fragment>
      <Row>
        <center>
          <Col sm="12">
            <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
              <FormGroup className='col-lg-4'>
                <div className="text-center btn-mb">
                  <Button className="primary"><H1>Pequena</H1></Button>
                </div>
              </FormGroup>
              <FormGroup className='col-lg-4'>            
                <div className="text-center btn-mb">
                  <Button className="primary"><H1>Média</H1></Button>
                </div>            
              </FormGroup>
              <FormGroup className='col-lg-4'>            
                <div className="text-center btn-mb">
                  <Button className="primary"><H1>Grande</H1></Button>
                </div>            
              </FormGroup>              
            </Form>
          </Col>
        </center>
      </Row>
    </Fragment>
  );
};

export default OpcaoTamanhoPizza;
