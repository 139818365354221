import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Form, FormGroup, Button } from "reactstrap";
import { H1 } from '../../AbstractElements';

const MontagemEstruturaProdutoPedido = ({ setSteps, setFormdata}) => {
  const {
    handleSubmit
  } = useForm();
  const onSubmit = (data) => {
    setFormdata((prev) => ({ ...prev, ...data }));
    setSteps((prev) => prev + 1);
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
        <center>      
          <Row>
            <Col sm="12">
              <FormGroup className='col-lg-3'>
                <H1>Estrutura</H1>          
              </FormGroup>
            </Col>
          </Row>
        </ center> 
        <center>              
          <Row>
              <Col sm="6">
                <FormGroup className='col-md-6'>
                  <div className="text-center btn-mb">
                    <Button className="primary"><H1>Cancelar</H1></Button>
                  </div>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className='col-md-6'>                
                  <div className="text-center btn-mb">
                    <Button className="secondary"><H1>Finalizar</H1></Button>
                  </div>
                </FormGroup>
              </Col>              
          </Row>
        </center>   
      </Form>         
    </Fragment>
  );
};

export default MontagemEstruturaProdutoPedido;
