import Context from './index';
import React, {  useState } from 'react';
import axios from 'axios';
import { UsuarioApi } from '../../api';
import {  toast } from "react-toastify";


const UsuarioProvider = (props) => {
    const [users, setUsers] = useState([]);
    const [data] = useState([]);
    const [user, setUser] = useState('');   

    const getUsers = () => {
        try {
             axios.get(`${UsuarioApi}/GetAll`).then((resp) => {
                setUsers(resp.data);
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    const getUser = (id) => {
        try {
             axios.get(`${UsuarioApi}/GetById/`+ id).then((resp) => {
                setUser('');
                setUser(resp.data);
            });
        } catch (error) {
            setUser('');
            console.log('error', error);
        }
    };    
/*
    useEffect(() => {
        getUsers();
    }, [setUsers, setData]);
*/
    const createUser = (data) => {
        const userTemp = {
            idPerfil: data.idPerfil,            
            nome: data.nome,
            email: data.email,
            senha: data.senha,
            ativo: true
        };

        axios.post(`${UsuarioApi}/add`, userTemp)
          .then((response) => {
            getUsers();
            toast.success("O registro inserido com sucesso!");            
          });        
    };

    const editUser = (data, id) => {
        const userTemp = {
            id: id,
            idPerfil: data.idPerfil,            
            nome: data.nome,
            email: data.email,
            senha: data.senha,
            ativo: data.ativo
        };

        axios.put(`${UsuarioApi}/update`, userTemp)
          .then((response) => {
            getUsers();
            toast.success("O registro alterado com sucesso!");            
          }); 

    };

    const deletedUser  = (id) => {
        axios.delete(`${UsuarioApi}/delete/` + id)
          .then((response) => {
            getUsers();
            toast.success("O registro excluído com sucesso!");            
          });        
    };

    return (
        <Context.Provider
            value={{
                ...props,
                users,
                data,
                user,
                createUser: createUser,
                editUser: editUser,
                deletedUser: deletedUser,
                getUsers: getUsers,
                setUsers: setUsers,
                getUser: getUser,
                setUser: setUser
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default UsuarioProvider;
