import React, { Fragment, useState, useCallback  } from 'react';
import { Input, InputGroupText, Label, Col, FormGroup } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import CategoriaProdutoSelecaoPedidoComponent from './CategoriaProdutoSelecaoPedidoComponent';

const Commen = (props) => {

  const [valorTotal, setValorTotal] = useState(props.objData.valorTotal);  
  const [tamanho, setTamanho] = useState('p');    

  const atualizaValor = useCallback(state => {
    setValorTotal(props.objData.valorTotal);
}, [valorTotal]);

  const Minus  = useCallback(state => {

    var listChk = document.getElementsByName('rd' + props.objData.id);

    if (listChk.length > 0){
      for(var i = 0; i < listChk.length; i++) {
        if(listChk[i].checked) {

          setTamanho(listChk[i].value);          

          if (props.objData.quantidadePedido === 0)
            props.setVal(props.objData.id, 0);
          else
          {
            props.setVal(props.objData.id, props.objData.quantidadePedido - 1, listChk[i].value);
            let element = document.getElementById('qtd' + props.objData.id);    
            element.value = props.objData.quantidadePedido;
          }
          atualizaValor();
        }
      }
    }
    else{

      if (tamanho === undefined){
        setTamanho("p");
      }          

      if (props.objData.quantidadePedido === 0)
        props.setVal(props.objData.id, 0);
      else
      {
        props.setVal(props.objData.id, props.objData.quantidadePedido - 1, tamanho);
        let element = document.getElementById('qtd' + props.objData.id);    
        element.value = props.objData.quantidadePedido;
      }
      atualizaValor();      
    }

  },[valorTotal]);

  const Addition  = useCallback(state => {

    var listChk = document.getElementsByName('rd' + props.objData.id);

    if (listChk.length > 0){
      for(var i = 0; i < listChk.length; i++) {
        if(listChk[i].checked) {
          
          setTamanho(listChk[i].value);

          props.setVal(props.objData.id, props.objData.quantidadePedido + 1, listChk[i].value);
          let element = document.getElementById('qtd' + props.objData.id);    
          element.value = props.objData.quantidadePedido + 1;
          atualizaValor();        
          }
      }        
    }
    else{

      if (tamanho === undefined){
        setTamanho("p");
      }          
      props.setVal(props.objData.id, props.objData.quantidadePedido + 1, tamanho);
      let element = document.getElementById('qtd' + props.objData.id);    
      element.value = props.objData.quantidadePedido + 1;
      atualizaValor();              

    }    

  },[valorTotal]);

  const handleChangeTamanho  = (e) => {

    setTamanho(e.target.value);

    props.objData.tamanho = e.target.value;

    if (e.target.value === "p")
    {
      props.objData.valorSelecionado = props.objData.precoPequeno;
    }

      if (e.target.value === "m")
      {
        props.objData.valorSelecionado = props.objData.precoMedio;      
      }

      if (e.target.value === "g")
      {
        props.objData.valorSelecionado = props.objData.precoGrande;            
      }

      let element = document.getElementById('igt' + props.objData.id);    
      element.innerHTML= "R$ " + props.objData.valorSelecionado;
    
      props.setVal(props.objData.id, props.objData.quantidadePedido, e.target.value);

      atualizaValor();
  };

  const handleIngrediente = (e, idxPrd, ingId) => {

    props.objData.tamanho = tamanho;

    props.objData.ingredientes.map((ing) =>
    {
      if(ing.id === ingId)
      {
        if (e.target.checked)
        {
          ing.selecionado = true;
        }
        else
        {
          ing.selecionado = false;
        }
      }
    });

    props.setOpcao(props.objData);

  }; 

  function IngredientesProduto (props) {

    let ret = [];

    if (props.produto.ingredientes !== null)
    {
      ret = props.produto.ingredientes
    };
    return (
      <Fragment>
        <div className='form-row'>
            {ret?.map((ing) =>                                              
              (ing.ativo === true) && <FormGroup className='col-md-6' key={'grpForm' + props.produto.id + 'prd' + ing.id}>
                <div className='checkbox checkbox-primary'>
                  <Input id={'chkPai' + props.produto.id + 'prd' + ing.id} type="checkbox" defaultChecked={ing.selecionado}
                  onChange={e => handleIngrediente(e,props.produto.id,ing.id)}
                  />
                  <Label id={'lblchkPai' + props.produto.id + 'prd' + ing.id} htmlFor={'chkPai' + props.produto.id + 'prd' + ing.id}>{ing.descricao}</Label>
                </div>
              </FormGroup>
            )}                                                
          </div> 
      </Fragment>

    )
  };

  return (
    <Fragment>
      {(props.objCategoria.tipoUso === "F") &&
      <div className="form-row  col-md-7">      
        <div className={`input-group bootstrap-touchspin`}>
          <InputGroupText>
              <Col>
                <div className='m-t-15 m-checkbox-inline custom-radio-ml'>
                  <div className='radio radio-primary'>
                    <Input id={'rd1' + props.objData.id} type='radio' name={'rd' + props.objData.id} value='p'  defaultChecked onChange={e => handleChangeTamanho(e)}/>
                    <Label id={'lblrd1' + props.objData.id} className='mb-0' for={'rd1' + props.objData.id}>
                    Pequena
                    </Label>
                  </div>
                  <div className='radio radio-primary'>
                    <Input id={'rd2' + props.objData.id} type='radio' name={'rd' + props.objData.id} value='m' onChange={e => handleChangeTamanho(e)}/>
                    <Label id={'lblrd2' + props.objData.id} className='mb-0' for={'rd2' + props.objData.id}>
                    Média
                    </Label>
                  </div>
                  <div className='radio radio-primary'>
                    <Input id={'rd3' + props.objData.id} type='radio' name={'rd' + props.objData.id} value='g' onChange={e => handleChangeTamanho(e)}/>
                    <Label id={'lblrd3' + props.objData.id} className='mb-0' for={'rd3' + props.objData.id}>
                      Grande
                    </Label>
                  </div>
                </div>
              </Col>      
          </InputGroupText>               
          <InputGroupText id={'igt' + props.objData.id} >R$ {props.objData.precoPequeno.toFixed(2)}</InputGroupText>                    
          <Btn attrBtn={{ color: 'primary', className: 'bootstrap-touchspin-down', onClick: Minus }}>
            <i className='fa fa-minus'></i>
          </Btn>
          <Input className='touchspin text-start' type='text' name={'qtd' + props.objData.id} id={'qtd' + props.objData.id}  style={{ display: 'block' }} value={props.objData.quantidadePedido} readOnly />
          <Btn attrBtn={{ color: 'primary btn-square', className: 'bootstrap-touchspin-up', onClick: Addition }}>
            <i className='fa fa-plus'></i>
          </Btn>
          <InputGroupText>R$ {valorTotal.toFixed(2)}</InputGroupText>  
        </div> 
        <div className="form-row"> 
          {(props.objData.quantidadePedido > 0) &&
            <FormGroup key={'frmingsPrd' + props.objData.id} className='col-md-12' style={{marginLeft: 10}}>
                <IngredientesProduto key={'ingsPrd' + props.objData.id} produto={props.objData} /> 
            </FormGroup>
          }
        </div>
      </div>
      }

      {(props.objCategoria.tipoUso === "V") &&
        <div className={`input-group bootstrap-touchspin ${props.groupText ? 'input-group-text-none' : ''}`}>
          <InputGroupText>R$ {props.objData.precoPequeno.toFixed(2)}</InputGroupText>                    
          <Btn attrBtn={{ color: 'primary', className: 'bootstrap-touchspin-down', onClick: Minus }}>
            <i className='fa fa-minus'></i>
          </Btn>
          <Input className='touchspin text-start' type='text' name={'qtd' + props.objData.id} id={'qtd' + props.objData.id}  style={{ display: 'block' }} value={props.objData.quantidadePedido} readOnly />
          <Btn attrBtn={{ color: 'primary btn-square', className: 'bootstrap-touchspin-up', onClick: Addition }}>
            <i className='fa fa-plus'></i>
          </Btn>
          <InputGroupText>R$ {valorTotal.toFixed(2)}</InputGroupText>                              
        </div>
      }

    {(props.objCategoria.tipoUso === "C") &&
      <div className="form-row col-md-7">      
        <div className={`input-group bootstrap-touchspin`}>
          <InputGroupText>
              <Col>
                <div className='m-t-15 m-checkbox-inline custom-radio-ml'>
                  <div className='radio radio-primary'>
                    <Input id={'rd1' + props.objData.id} type='radio' name={'rd' + props.objData.id} value='p'  defaultChecked onChange={e => handleChangeTamanho(e)}/>
                    <Label id={'lblrd1' + props.objData.id} className='mb-0' for={'rd1' + props.objData.id}>
                    Pequena
                    </Label>
                  </div>
                  <div className='radio radio-primary'>
                    <Input id={'rd2' + props.objData.id} type='radio' name={'rd' + props.objData.id} value='m' onChange={e => handleChangeTamanho(e)}/>
                    <Label id={'lblrd2' + props.objData.id} className='mb-0' for={'rd2' + props.objData.id}>
                    Média
                    </Label>
                  </div>
                  <div className='radio radio-primary'>
                    <Input id={'rd3' + props.objData.id} type='radio' name={'rd' + props.objData.id} value='g' onChange={e => handleChangeTamanho(e)}/>
                    <Label id={'lblrd3' + props.objData.id} className='mb-0' for={'rd3' + props.objData.id}>
                      Grande
                    </Label>
                  </div>
                </div>
              </Col>      
          </InputGroupText>               
          <InputGroupText id={'igt' + props.objData.id} >R$ {props.objData.precoPequeno.toFixed(2)}</InputGroupText>                    
          <Btn attrBtn={{ color: 'primary', className: 'bootstrap-touchspin-down', onClick: Minus }}>
            <i className='fa fa-minus'></i>
          </Btn>
          <Input className='touchspin text-start' type='text' name={'qtd' + props.objData.id} id={'qtd' + props.objData.id}  style={{ display: 'block' }} value={props.objData.quantidadePedido} readOnly />
          <Btn attrBtn={{ color: 'primary btn-square', className: 'bootstrap-touchspin-up', onClick: Addition }}>
            <i className='fa fa-plus'></i>
          </Btn>
          <InputGroupText id={'idgTotPrd' + props.objData.id} >R$ {valorTotal.toFixed(2)}</InputGroupText>  
        </div> 
        </div> 
      }
      {( props.objCategoria.tipoUso === "C" && props.objCategorias !== "" && props.objCategorias !== undefined && props.objCategorias.length !== 0 
      && props.objData.quantidadePedido > 0) &&
        <div className="form-row col-md-12">          
            <CategoriaProdutoSelecaoPedidoComponent id={'catPrd' + props.objData.id} categorias={props.objCategorias} objData={props.objData} setVal={props.setVal} tamanho={tamanho} />            
        </div>                
      }
    </Fragment>
  );
};
export default Commen;
