import React, { Fragment, useCallback, useEffect, useState, useContext } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../AbstractElements';
import CategoriaAppContext from '../../_helper/Categoria';
import SweetAlert from 'sweetalert2';
import { Modal, ModalHeader, ModalBody, Label, Input, FormGroup, Form, Media, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import {  toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const tableColumns = [
    {
        name: 'Código',
        selector: row => row['id'],
        sortable: true,
        center: false,
    },
    {
        name: 'Categoria',
        selector: row => row['descricao'],
        sortable: true,
        center: false,
    },
    {
        name: 'Ativo',
        selector: row => row['ativo'] === true ? <span className='badge badge-light-success'>Sim</span> : <span className='badge badge-light-danger'>Não</span>,
        sortable: true,
        center: true,
   }
];

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <Col>
  <Label id="lblPesquisa">Filtro</Label>
    <Input
      id="search"
      type="text"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
  </Col>
);  

const CategoriaComponent = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const { deletedCategoria, getCategorias, categorias, categoria, getCategoria } = useContext(CategoriaAppContext);    
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);    

    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };
  
      return (
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
    }, [filterText, resetPaginationToggle]);

    const filteredItems = categorias.filter(
      item => item.descricao && item.descricao.toLowerCase().includes(filterText.toLowerCase()),
    );    

    useEffect(() => {
        getCategorias();
    },[]);

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
        if (state.selectedRows.length > 0){
          getCategoria(state.selectedRows[0].id);
        }
    }, []);

    const handleDelete = ()  =>  {

        SweetAlert.fire({
            text: `Deseja excluir o registro:\r ${selectedRows.map(r => r.id)}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        })
            .then(async (result) => {
                if (result.value) {
                   
                    await deletedCategoria(selectedRows.map(r => r.id));
                    
                    setToggleDelet(!toggleDelet);

                    setSelectedRows('');
                } else {

                  toast.info("Operação Cancelada!");          
                }
            });
    };

  

    return (
        <Fragment>
            <ModalInserir  props={{ titulo: 'Novo', id: 0}} />
            <DataTable
                data={filteredItems}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}                
                selectableRows
                subHeader
                subHeaderComponent={subHeaderComponentMemo}                
                selectableRowsSingle
                selectionMode="single"
                onSelectedRowsChange={handleRowSelected}                
                Clicked               
                clearSelectedRows={toggleDelet}
                noDataComponent="Não existem registros para serem exibidos."                
            />
            {(selectedRows.length !== 0 && categoria !== "" && categoria !== undefined) && 
                <div className={`d-flex align-items-right bg-light-info p-2`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}></H4>
                    <ModalEditar  
                        titulo= 'Alterar'
                        id={selectedRows.map(r => r.id)}
                        dataEdit={categoria}
                        />
                    {(categoria.produtos?.length === 0) && <Btn attrBtn={{ color: 'danger', onClick: () => handleDelete() }}>Excluir</Btn>}
                </div>
            }

        </Fragment>
    )
}

function ModalInserir ({props}) {
    const [modal, setModal] = useState(false);
    const [ativo, setAtivo] = useState(false);           
    const [tipoUso, setTipoUso] = useState('');        
    const [idCategoriaPai, setCategoriaPai] = useState('');        
    const { createCategoria, getCategorias, categorias } = useContext(CategoriaAppContext);    
 
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset
    } = useForm();


    const toggle = () => {

      setModal(!modal);

      setTipoUso('C');        
      setCategoriaPai('');
      setAtivo(true);

      reset((formValues) => ({
        ...formValues,
        descricao : '',        
        id: 0,
        idCategoriaPai: '',
        tipoUso: 'C',
        ativo: true
      }));        


    };
  
    const Adicionar = (data) => {
      if (data !== '') {
        data.tipoUso = tipoUso;
        data.ativo = ativo;

        if ( idCategoriaPai === "0" || idCategoriaPai === "")
          data.idCategoriaPai = undefined;
        else
          data.idCategoriaPai = idCategoriaPai;

        createCategoria(data);
        setModal(false);
      } else {
        errors.showMessages();
      }
    };
  
      useEffect(() => {
          getCategorias();
      },[reset]);
   
      const handleChangeAtivo = (e) => {
        setAtivo(e.target.checked);
      };      

      const handleChangeTipoUso = (e) => {
        setTipoUso(e.target.value);
      };            

      const handleChangeCategoriaPai = (e) => {
        setCategoriaPai(e.target.value);
      };                  


    return (
      <Fragment>
        <Btn attrBtn={{color: 'primary', onClick:  toggle }}>{props.titulo}</Btn>
        <Modal className='modal-bookmark' isOpen={modal} toggle={toggle} size='lg'>
          <ModalHeader toggle={toggle}>{props.titulo}</ModalHeader>
          <ModalBody>
            <Form className='form-bookmark needs-validation' onSubmit={handleSubmit(Adicionar)}>
  
  
              <div className='form-row'>
                
                <FormGroup className='col-md-12'>
                  <Label>Nome</Label>
                  <input className='form-control' name='descricao' type='text' {...register('descricao', { required: true })} />
                  <span style={{ color: 'red' }}>{errors.descricao && 'Conteúdo obrigatório'}</span>
                </FormGroup>              
              </div>

              <div className='form-row'>
                <FormGroup className='col-md-6'>
                    <Label htmlFor="exampleFormControlSelect9">Tipo de Uso</Label>
                    <Input type="select" name="select" className="form-control digits" onChange={e => handleChangeTipoUso(e)} >
                        <option value="C">Criado pelo Cliente</option>
                        <option value="V">Vendido a Parte</option>
                        <option value="P">Preparação</option>
                        <option value="F">Fabricação Própria</option>         
                    </Input>
                  </FormGroup>

                  <FormGroup className='col-md-6'>
                    <Label htmlFor="selectCaegoria">Categoria Pai</Label>
                    <Input type="select" name="selectCategoria" className="form-control digits" 
                    onChange={e => handleChangeCategoriaPai(e)}>
                            <option key="empty" value="0">(Nenhum)</option>                      
                            {categorias.map((data, i) => (
                                        <option key={data.id} value={data.id}>{data.descricao}</option>
                            ))};
                    </Input>
                  </FormGroup>

              </div>

              <FormGroup className='col-md-2'>
                  <Media key="swativo">
                    <Label className='col-form-label m-r-10'>Ativo</Label>
                    <Media body className='text-end'>
                      <Label className='switch'>
                        <Input type='checkbox' {...register('ativo', { required: false })}
                          onChange={e => handleChangeAtivo(e)}
                          defaultChecked={ativo}
                        />
                        <span className='switch-state'></span>
                      </Label>
                    </Media>
                  </Media>
                </FormGroup>              

              <Btn attrBtn={{ color: 'primary', className: 'me-2' }}>Confirmar</Btn>
              <Btn attrBtn={{ color: 'secondary', onClick: toggle }}>Cancelar</Btn>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  };

  function ModalEditar ({titulo, id, dataEdit}) {
    const [modal, setModal] = useState(false);
    const history = useNavigate();    

    const { editCategoria, getCategorias, categorias } = useContext(CategoriaAppContext);    
    const [idCategoriaPai, setCategoriaPai] = useState('');       

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset
    } = useForm();

      useEffect(() => {
        getCategorias();
    },[]);   
    
    
    const toggle = () => {

      setModal(!modal);

      reset((formValues) => ({
        ...formValues,
        descricao : dataEdit.descricao,        
        id: dataEdit.id,
        idCategoriaPai: dataEdit.idCategoriaPai,
        tipoUso: dataEdit.tipoUso,
        ativo: dataEdit.ativo
      }));        


    };    

    const Alterar = (data) => {
      if (data !== '') {

                 
          data.tipoUso = dataEdit.tipoUso;

          data.ativo = dataEdit.ativo;

          data.idCategoriaPai = dataEdit.idCategoriaPai;

        editCategoria(data,dataEdit.id);
        clear();        
        setModal(false);
        history(`${process.env.PUBLIC_URL}/app/categoria/torrepizza`);        
      } else {
        errors.showMessages();
      }
    };

    const clear = () => {
        dataEdit = {
            id: 0,
            tipoUso: '',
            descricao: '',
            ativo: ''
        };
    };

    const handleChangeAtivo = (e) => {
      dataEdit.ativo = e.target.checked;
    };      

    const handleChangeTipoUso = (e) => {
      dataEdit.tipoUso = e.target.value;
    };            

    const handleChangeCategoriaPai = (e) => {
      dataEdit.idCategoriaPai = e.target.value;
    };         
   
    return (

      <Fragment>
        <Btn attrBtn={{color: 'primary', onClick:  toggle }}>Alteração</Btn>
        <Modal className='modal-bookmark' isOpen={modal} toggle={toggle} size='lg'>
          <ModalHeader toggle={toggle}>Alteração</ModalHeader>
          <ModalBody>
            <Form className='form-bookmark needs-validation' onSubmit={handleSubmit(Alterar)}>
  
  
              <div className='form-row'>
                
                <FormGroup className='col-md-12'>
                  <Label>Descrição</Label>
                  <input className='form-control' name='descricao' type='text' defaultValue={dataEdit.descricao}  {...register('descricao', { required: true })} />
                  <span style={{ color: 'red' }}>{errors.descricao && 'Conteúdo obrigatório'}</span>
                </FormGroup>              
              </div>

              <div className='form-row'>
                <FormGroup className='col-md-6'>
                    <Label htmlFor="exampleFormControlSelect9">Tipo de Uso</Label>
                    <Input type="select" name="select" className="form-control digits" defaultValue={dataEdit.tipoUso}  onChange={e => handleChangeTipoUso(e)} >
                        <option value="C">Criado pelo Cliente</option>
                        <option value="V">Vendido a Parte</option>
                        <option value="P">Preparação</option>
                        <option value="F">Fabricação Própria</option>                        
                    </Input>
                  </FormGroup>

                  <FormGroup className='col-md-6'>
                    <Label htmlFor="selectCaegoria">Categoria Pai</Label>
                    <Input type="select" name="selectCategoria" className="form-control digits" defaultValue={dataEdit.idCategoriaPai} 
                    onChange={e => handleChangeCategoriaPai(e)}>
                            <option key="empty" value="0">(Nenhum)</option>                      
                            {categorias.map((data, i) => (
                                        <option key={data.id} value={data.id}>{data.descricao}</option>
                            ))};
                    </Input>
                  </FormGroup>

              </div>

              <FormGroup className='col-md-2'>
                  <Media key="swativo">
                    <Label className='col-form-label m-r-10'>Ativo</Label>
                    <Media body className='text-end'>
                      <Label className='switch'>
                        <Input type='checkbox' defaultChecked={dataEdit.ativo} defaultValue={dataEdit.ativo}
                          onChange={e => handleChangeAtivo(e)}
                        />
                        <span className='switch-state'></span>
                      </Label>
                    </Media>
                  </Media>
                </FormGroup>              

              <Btn attrBtn={{ color: 'primary', className: 'me-2' }}>Confirmar</Btn>
              <Btn attrBtn={{ color: 'secondary', onClick: toggle }}>Cancelar</Btn>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  };
  
  
export default CategoriaComponent
