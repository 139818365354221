import React, { Fragment, useCallback, useEffect, useState, useContext } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../AbstractElements';
import UsuarioAppContext from '../../_helper/Usuario';
import { Row, Col, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, Form, Media } from 'reactstrap';
import { useForm } from 'react-hook-form';
import {  toast } from "react-toastify";
import SweetAlert from 'sweetalert2';

import axios from 'axios';
import { UsuarioApi } from '../../api';

export const tableColumns = [
    {
        name: 'Código',
        selector: row => row['id'],
        sortable: true,
        center: false,
    },
    {
        name: 'Nome',
        selector: row => row['nome'],
        sortable: true,
        center: false,
    },
    {
        name: 'Perfil',
        selector: row => getPerfil(row['idPerfil']),
        sortable: true,
        center: false,
    },    
    {
        name: 'Email',
        selector: row => row['email'],
        sortable: true,
        center: false,
    },
    {
        name: 'Ativo',
        selector: row => row['ativo'] === true ? <span className='badge badge-light-success'>Sim</span> : <span className='badge badge-light-danger'>Não</span>,
        sortable: true,
        center: true,
   }
];
const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<Col>
  <Label id="lblPesquisa">Filtro</Label>
		<Input
			id="search"
			type="text"
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
		/>
	</Col>
);

const getPerfil = (id) =>{
    switch(id) {
        case 'A':
            return 'Administrador';
        case 'C':
            return 'Cliente TOTEM';                                      
        default:
            return 'X';
      }
}

const UsuarioComponent = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const { deletedUser, getUsers, users, user, getUser, setUser } = useContext(UsuarioAppContext);    

    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };
  
      return (
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
    }, [filterText, resetPaginationToggle]);

    useEffect(() => {
        getUsers();
    },[]);

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);

        if (state.selectedRows.length > 0){
          getUser(state.selectedRows[0].id);
        }
    }, []);

    const handleDelete = ()  =>  {

        SweetAlert.fire({
            text: `Deseja excluir o registro:\r ${selectedRows.map(r => r.id)}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        })
            .then(async (result) => {
                if (result.value) {
                   
                    await deletedUser(selectedRows.map(r => r.id));
                    
                    setToggleDelet(!toggleDelet);

                    setSelectedRows('');
                } else {
                  toast.info("Operação Cancelada!");          
                }
            });
    };

    const filteredItems = users.filter(
      item => item.nome && item.nome.toLowerCase().includes(filterText.toLowerCase()),
    );

    return (
        <Fragment>
            <ModalInserir  props={{ titulo: 'Novo', id: 0}} />
            <DataTable
                data={filteredItems}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                selectableRows
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                selectableRowsSingle
                selectionMode="single"
                onSelectedRowsChange={handleRowSelected}                
                Clicked               
                clearSelectedRows={toggleDelet}
                noDataComponent="Não existem registros para serem exibidos."                
            />
            {(selectedRows.length !== 0 && user !== "" && user !== undefined) &&             
                <div className={`d-flex align-items-right bg-light-info p-2`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}></H4>
                    <ModalEditar  
                        titulo= 'Alterar'
                        id={selectedRows.map(r => r.id)}
                        dataEdit={user}
                        />
                    {(selectedRows.map(r => r.nome)[0] !== 'Administrador') && <Btn attrBtn={{ color: 'danger', onClick: () => handleDelete() }}>Excluir</Btn>}
                </div>
            }

        </Fragment>
    )
}

function ModalInserir ({props}) {
    const [modal, setModal] = useState(false);
    const [idPerfil, setPerfil] = useState('A');         
    const { createUser } = useContext(UsuarioAppContext);       
    const [perfis, setPerfis] = useState([{id: 'A', descricao: 'Administrador'},
                                          {id: 'C', descricao: 'Cliente TOTEM'}]);                
 
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset
    } = useForm({
      defaultValues: {
        idPerfil : 'T',
        id: 0,
        nome: '',
        email: '',
        senha: '',
        ativo: ''
      },      
    });

    const toggle = () => {  
      setModal(!modal); 
    
      if (modal === true){
        reset((formValues) => ({
          ...formValues,
          idPerfil : 'A',        
          id: '',
          nome: '',
          email: '',
          senha: '',
          ativo: false
        }));        
      }      

    }    

    useEffect(() => {
      reset((formValues) => ({
        ...formValues,
        idPerfil : 'A',        
        id: 0,
        nome: '',
        email: '',
        senha: '',
        ativo: ''
      }));
    },[reset]);    
  
    const Adicionar = async (data) => {
      if (data !== '') 
      {

        await axios.get(`${UsuarioApi}/filterUsuarios?email=` + data.email)
          .then((response) => {

            if (response.data.lstUsuarios.length > 0)
              {
                toast.error("Usuário já cadastrado!");     
                return;
              }
  
              data.idPerfil = idPerfil;        
              
              createUser(data);
              setModal(false);
  
              setPerfil('A');
  
              reset((formValues) => ({
                ...formValues,
                id: 0,
                idPerfil : 'A',
                nome: '',
                email: '',
                senha: '',
                ativo: ''
              }));            

          });      
        
      } else {
        errors.showMessages();
      }        
        


    };

    const handleChangePerfil = (e) => {
      setPerfil(e.target.value);
    };                      
   
    return (
      <Fragment>
        <Btn attrBtn={{ color: 'primary', onClick:  toggle }}>{props.titulo}</Btn>
        <Modal className='modal-bookmark' isOpen={modal} toggle={toggle} size='lg'>
          <ModalHeader toggle={toggle}>{props.titulo}</ModalHeader>
          <ModalBody>
            <Form className='form-bookmark needs-validation' onSubmit={handleSubmit(Adicionar)}>
  
            <FormGroup className='col-md-12'>
              <Label htmlFor="exampleFormControlSelect9">Perfil</Label>
              <Input type="select" name="select" className="form-control digits" defaultValue={idPerfil}
                      onChange={e => handleChangePerfil(e)}>
                  {perfis.map((data, i) => (
                                          <option key={data.id} value={data.id}>{data.descricao}</option>
                              ))};
              </Input>
          </FormGroup>
  
  
              <div className='form-row'>
                <FormGroup className='col-md-12'>
                  <Label>Nome</Label>
                  <input className='form-control' name='nome' type='text' {...register('nome', { required: true })} />
                  <span style={{ color: 'red' }}>{errors.nome && 'Conteúdo obrigatório'}</span>
                </FormGroup>              
                <FormGroup className='col-md-12'>
                  <Row>
                    <Col sm='6'>
                      <Label>Usuário</Label>                    
                      <input className='form-control' name='email' type='text' 
                      {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}/>
                      <span style={{ color: 'red' }}>{errors.email && 'E-mail inválido ou não informado.'}</span>
                    </Col>
                    <Col sm='6'>
                      <Label>Senha</Label>                                        
                      <input className='form-control' name='senha' type='password' minLength={4} {...register('senha', { required: true })} />
                      <span style={{ color: 'red' }}>{errors.senha && 'Conteúdo obrigatório'}</span>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className='col-md-2'>
  
                  <Media key="swativo">
                      <Label className='col-form-label m-r-10'>Ativo</Label>
                      <Media body className='text-end'>
                        <Label className='switch'>
                          <Input type='checkbox' checked {...register('ativo', { required: false })}/>
                          <span className='switch-state'></span>
                        </Label>
                      </Media>
                    </Media>
                    
  
                </FormGroup>
              </div>
              <Btn attrBtn={{ color: 'primary', className: 'me-2' }}>Confirmar</Btn>
              <Btn attrBtn={{ color: 'secondary', onClick: toggle }}>Cancelar</Btn>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  };

  function ModalEditar ({titulo, id, dataEdit}) {
    const [modal, setModal] = useState(false);
    const { editUser } = useContext(UsuarioAppContext);    

    const [selectedPerfil, setSelectedPerfil] = useState(dataEdit.idPerfil);   
    const [perfis, setPerfis] = useState([{id: 'A', descricao: 'Administrador'},
                                          {id: 'C', descricao: 'Cliente TOTEM'}]);      

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset
    } = useForm();

    const toggle = () => {
      setModal(!modal);   

      if (modal === true){
        reset((formValues) => ({
          ...formValues,
          idPerfil : 'A',        
          id: '',
          nome: '',
          email: '',
          senha: '',
          ativo: false
        }));        
      }
      else {

        setSelectedPerfil(dataEdit.idPerfil);

        reset((formValues) => ({
          ...formValues,
          idPerfil : dataEdit.idPerfil,        
          id: dataEdit.id,
          nome: dataEdit.nome,
          email: dataEdit.email,
          senha: dataEdit.senha,
          ativo: dataEdit.ativo
        }));        
      }

    }  

    useEffect(() => {
      reset((formValues) => ({
        ...formValues,
        idPerfil : dataEdit.idPerfil,        
        id: dataEdit.id,
        nome: dataEdit.nome,
        email: dataEdit.email,
        senha: dataEdit.senha,
        ativo: dataEdit.ativo
      }));
    },[reset]);    


    const handleChangeAtivo = (e) => {
      dataEdit.ativo = e.target.checked;
    };      

    const Alterar = (data) => {
      if (data !== '') {
        dataEdit.idPerfil = selectedPerfil;
        data.idPerfil = selectedPerfil;
        data.ativo = dataEdit.ativo;        
        editUser(data,dataEdit.id);
        clear();        
        setModal(false);
      } else {
        errors.showMessages();
      }
    };

    const clear = () => {
        dataEdit = {
            id: 0,
            nome: '',
            email: '',
            senha: '',
            ativo: ''
        };
    }
   
    return (
      <Fragment>
        <Btn attrBtn={{ color: 'primary', onClick:  toggle }}>Alteração</Btn>
        <Modal className='modal-bookmark' isOpen={modal} toggle={toggle} size='lg'>
          <ModalHeader toggle={toggle}>Alteração</ModalHeader>
          <ModalBody>
            <Form className='form-bookmark needs-validation' onSubmit={handleSubmit(Alterar)} id="editar-form">
  
            <FormGroup className='col-md-12'>
              <Label htmlFor="exampleFormControlSelect9">Perfil</Label>
              <Input type="select" name="select" className="form-control digits" defaultValue={dataEdit.idPerfil} 
                onChange={e => setSelectedPerfil(e.target.value)} disabled={dataEdit.nome === 'Administrador'} >
                  {perfis.map((data, i) => (
                                          <option key={data.id} value={data.id}>{data.descricao}</option>
                              ))};
              </Input>
          </FormGroup>
  
  
              <div className='form-row'>
                <FormGroup className='col-md-12'>
                  <Label>Nome</Label>
                  <input className='form-control' name='nome' type='text' defaultValue={dataEdit.nome} 
                  disabled={dataEdit.nome === 'Administrador'}                   
                  {...register('nome', { required: true })} />
                  <span style={{ color: 'red' }}>{errors.nome && 'Conteúdo obrigatório'}</span>
                </FormGroup>              
                <FormGroup className='col-md-12'>
                  <Row>
                    <Col sm='6'>
                      <Label>Usuário</Label>                    
                      <input className='form-control' name='email' type='text' defaultValue={dataEdit.email}  
                      disabled                      
                      {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}/>
                      <span style={{ color: 'red' }}>{errors.email && 'E-mail inválido ou não informado.'}</span>
                    </Col>
                    <Col sm='6'>
                      <Label>Senha</Label>                                        
                      <input className='form-control' name='senha' type='password' defaultValue={dataEdit.senha}  {...register('senha', { required: true })} />
                      <span style={{ color: 'red' }}>{errors.senha && 'Conteúdo obrigatório'}</span>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className='col-md-2'>
  
                  <Media key="swativo">
                      <Label className='col-form-label m-r-10'>Ativo</Label>
                      <Media body className='text-end'>
                        <Label className='switch'>
                          <Input type='checkbox' 
                          disabled={dataEdit.nome === 'Administrador'}                          
                          onChange={e => handleChangeAtivo(e)}
                          defaultChecked={dataEdit.ativo} defaultValue={dataEdit.ativo}/>
                          <span className='switch-state'></span>
                        </Label>
                      </Media>
                    </Media>
                    
  
                </FormGroup>
              </div>
              <Btn attrBtn={{ color: 'primary', className: 'me-2' }}>Confirmar</Btn>
              <Btn attrBtn={{ color: 'secondary', onClick: toggle }}>Cancelar</Btn>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  };
  

export default UsuarioComponent;