import React, { Fragment, useState } from "react";

import BemVindo from "./BemVindo";
import IdentificacaoPedido from "./IdentificacaoPedido";
import MenuPedido from "./MenuPedido";
import OpcaoPedidoPizza from "./OpcaoPedidoPizza";
import OpcaoTamanhoPizza from "./OpcaoTamanhoPizza";
import MontagemEstruturaProdutoPedido from "./MontagemEstruturaProdutoPedido";
import DiversosPedido from "./DiversosPedido";
import ResumoPedido from "./ResumoPedido";

import { Container, Row, Col, Card, CardBody } from "reactstrap";

const WizardPedido = (props) => {
  const [steps, setSteps] = useState(1);
  const [formdata, setFormdata] = useState({});

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {steps === 1 && <BemVindo setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} pedido={props.pedido} />}

                {steps === 2 && <IdentificacaoPedido setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} pedido={props.pedido}/>}

                {steps === 3 && <MenuPedido setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} pedido={props.pedido}/>}

                {steps === 4 && <OpcaoPedidoPizza setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} pedido={props.pedido}/>}                

                {steps === 5 && <OpcaoTamanhoPizza setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} pedido={props.pedido}/>}                                                

                {steps === 6 && <MontagemEstruturaProdutoPedido setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} pedido={props.pedido}/>}                                                                

                {steps === 7 && <DiversosPedido setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} pedido={props.pedido}/>}

                {steps === 8 && <ResumoPedido setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} pedido={props.pedido}/>}                

                <div className="text-center">
                  <span className={`step ${steps > 1 ? "finish" : ""} ${steps === 1 ? "active" : ""}`} />
                  <span className={`step ${steps > 2 ? "finish" : ""} ${steps === 2 ? "active" : ""}`} />
                  <span className={`step ${steps > 3 ? "finish" : ""} ${steps === 3 ? "active" : ""}`} />
                  <span className={`step ${steps > 4 ? "finish" : ""} ${steps === 4 ? "active" : ""}`} />
                  <span className={`step ${steps > 5 ? "finish" : ""} ${steps === 5 ? "active" : ""}`} />
                  <span className={`step ${steps > 6 ? "finish" : ""} ${steps === 6 ? "active" : ""}`} />
                  <span className={`step ${steps > 7 ? "finish" : ""} ${steps === 7 ? "active" : ""}`} />
                  <span className={`step ${steps > 8 ? "finish" : ""} ${steps === 8 ? "active" : ""}`} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default WizardPedido;
