import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Form, Label, FormGroup, Button } from "reactstrap";
import {  H4,H2,H1 } from '../../AbstractElements';

const DiversosPedido = ({ setSteps, setFormdata, formdata, pedido }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setFormdata((prev) => ({ ...prev, ...data }));
    setSteps((prev) => prev + 1);
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
        <center>      
          <Row>
            <Col sm="12">
              <FormGroup className='col-lg-3'>
                <H1>DIVERSOS PEDIDOS</H1>          
              </FormGroup>
            </Col>
          </Row>
        </ center> 
        <center>              
          <Row>
              <Col sm="4">
                <FormGroup className='col-md-6'>
                  <div className="text-center btn-mb">
                    <Button className="secondary me-2" onClick={() => setSteps((pre) => pre - 1)}>
                      <H1>Voltar</H1>
                    </Button>  
                  </div>
                </FormGroup>
              </Col>            
              <Col sm="4">
                <FormGroup className='col-md-6'>
                  <div className="text-center btn-mb">
                    <Button className="primary"><H1>Cancelar</H1></Button>
                  </div>
                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup className='col-md-6'>                
                  <div className="text-center btn-mb">
                    <Button className="secondary"><H1>Finalizar</H1></Button>
                  </div>
                </FormGroup>
              </Col>   

          </Row>
        </center>   
      </Form>         
    </Fragment>
  );
};

export default DiversosPedido;
