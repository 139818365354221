import Context from './index';
import React, {  useState } from 'react';
import axios from 'axios';
import { CategoriaApi } from '../../api';

import {  toast } from "react-toastify";

const CategoriaProvider = (props) => {
    const [categorias, setCategorias] = useState([]);
    const [data] = useState([]);
    const [categoria, setCategoria] = useState([]);    
    const [categoriasMenu, setCategoriasMenu] = useState([]);        

    const getCategorias = () => {
        try {
             axios.get(`${CategoriaApi}/GetAll`).then((resp) => {
                setCategorias(resp.data);
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    const getCategoriasTipoUso = (pTipouso) => {
        try {
             axios.get(`${CategoriaApi}/filterCategorias?Ativo=true&TipoUso=` + pTipouso).then((resp) => {
                setCategorias(resp.data);
            });
        } catch (error) {
            console.log('error', error);
        }
    };     

    const getCategoriasMenu = (pTipouso) => {
        try {
             axios.get(`${CategoriaApi}/filterCategoriaMenu`).then((resp) => {
                setCategoriasMenu(resp.data);
            });
        } catch (error) {
            console.log('error', error);
        }
    };         

        

    const getCategoria = (id) => {
        try {
             axios.get(`${CategoriaApi}/GetById/`+ id).then((resp) => {
                setCategoria('');
                setCategoria(resp.data);
            });
        } catch (error) {
            setCategoria('');
            console.log('error', error);
        }
    };    

    const createCategoria = (data) => {
        const categoriaTemp = {
            idCategoriaPai: data.idCategoriaPai,
            descricao: data.descricao,            
            tipoUso: data.tipoUso,
            ativo: data.ativo
        };

        axios.post(`${CategoriaApi}/add`, categoriaTemp)
          .then((response) => {
            getCategorias();
            toast.success("Registro inserido com sucesso!");                        
          });        
    };


    const editCategoria = (data, id) => {
        const categoriaTemp = {
            id: id,            
            idCategoriaPai: data.idCategoriaPai,            
            descricao: data.descricao,            
            tipoUso: data.tipoUso,
            ativo: data.ativo
        };

        axios.put(`${CategoriaApi}/update`, categoriaTemp)
          .then((response) => {
            getCategorias();
            toast.success("Registro alterado com sucesso!");            
          }); 

    };

    const deletedCategoria  = (id) => {
        axios.delete(`${CategoriaApi}/delete/` + id)
          .then((response) => {
            getCategorias();
            toast.success("Registro excluído com sucesso!");                        
          });        
    };

    return (
        <Context.Provider
            value={{
                ...props,
                categorias,
                categoriasMenu,                
                data,
                categoria,
                createCategoria: createCategoria,
                editCategoria: editCategoria,
                deletedCategoria: deletedCategoria,
                getCategorias: getCategorias,
                setCategorias: setCategorias,
                getCategoria: getCategoria,
                setCategoria: setCategoria,
                getCategoriasTipoUso: getCategoriasTipoUso,
                getCategoriasMenu: getCategoriasMenu
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default CategoriaProvider;
