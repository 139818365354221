import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Btn } from '../../../../AbstractElements';

const CommonModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        {props.title}
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.children}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'secondary', onClick: (e) => props.toggler(e) , id: 'btnModalCancel' }} >Cancelar</Btn>
        <Btn attrBtn={{ color: 'primary', onClick: (e) => props.toggler(e), id: 'btnModalConfirm' }}>Confirmar</Btn>
      </ModalFooter>
    </Modal>
  );
};

export default CommonModal;