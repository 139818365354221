import Context from './index';
import React, {  useState, useCallback } from 'react';
import axios from 'axios';
import { EstruturaProdutoApi } from '../../api';
import {  toast } from "react-toastify";
import SweetAlert from 'sweetalert2';

const EstruturaProdutoProvider = (props) => {
    const [list, setList] = useState([]);
    const [data] = useState([]);
    const [model, setModel] = useState([]);    
    const [idProduto, setIdProduto] = useState(false);        
 

    const getList = () => {
        try {
             axios.get(`${EstruturaProdutoApi}/GetAll`).then((resp) => {
                setList(resp.data);
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    const getModel = (id) => {
        try {
             axios.get(`${EstruturaProdutoApi}/GetById/`+ id).then((resp) => {
                setModel('');                
                setModel(resp.data);
            });
        } catch (error) {
            setModel('');            
            console.log('error', error);
        }
    };    

    
    const existeEstruturaProduto = async (idProduto) => {
        try {
             await axios.get(`${EstruturaProdutoApi}/filterEstruturaProdutos?idProduto=` + idProduto).then((resp) => {
                if (resp.data !== null && resp.data !== undefined && resp.data.lstEstruturaProdutos.length > 0) {
                    //setExisteProduto(true);
                    SweetAlert.fire({ title: '', text: 'Estrutura já cadastrada para o produto selecionado.', icon: 'error' });
                    let element = document.getElementById('selectProduto');            
                    element.selectedIndex = 0;
                    return;        
                }
                else{
                    setIdProduto(idProduto);
                }
            });
        } catch (error) {
            console.log('error', error);
        }
    };    

    const createData = (data) => {
        const modelTemp = {
            idProduto: data.idProduto,
            categorias: data.categorias,
            ativo: data.ativo
        };

        axios.post(`${EstruturaProdutoApi}/add`, modelTemp)
          .then((response) => {
            getList();
            toast.success("Registro inserido com sucesso!");            
          });        
    };


    const editData = (data, id) => {
        const modelTemp = {
            idProduto: data.idProduto,
            id: id,            
            categorias: data.categorias,
            ativo: data.ativo
        };

        axios.put(`${EstruturaProdutoApi}/update`, modelTemp)
          .then((response) => {
            getList();
            toast.success("Registro alterado com sucesso!");            
          }); 

    };

    const deleteData  = (id) => {
        axios.delete(`${EstruturaProdutoApi}/delete/` + id)
          .then((response) => {
            getList();
            toast.success("Registro excluído com sucesso!");            
          });        
    };

    return (
        <Context.Provider
            value={{
                ...props,
                list,
                data,
                model,
                idProduto, 
                setIdProduto: setIdProduto,
                createData: createData,
                editData: editData,
                deleteData: deleteData,
                getList: getList,
                setList: setList,
                getModel: getModel,
                setModel: setModel,
                existeEstruturaProduto: existeEstruturaProduto
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default EstruturaProdutoProvider;
