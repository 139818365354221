import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import PedidoRetaguardaComponent from './PedidoRetaguardaComponent';

const Usuarios = () => {

  return (
    <Fragment>
      <Breadcrumbs parent="Retaguarda" title="Pedido" mainTitle="Recebimento de Pedidos" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <PedidoRetaguardaComponent />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default Usuarios;