import React, { Fragment, useCallback, useEffect, useState, useContext } from 'react'
import { Btn } from '../../AbstractElements';
import PedidoAppContext from '../../_helper/Pedido';
import { Label, Row, Col, Modal, ModalHeader, ModalBody, Media, Input, FormGroup, Form,  Card, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Scrollbars from 'react-custom-scrollbars-2';
import { H6 } from '../../AbstractElements';


export const tableColumns = [
      {
        name: 'Data',
        selector: row => formatDate(row['dataPedido']),
        sortable: true,
        center: false,
    },  
    {
        name: 'Número',
        selector: row => row['idPedidoDia'],
        sortable: true,
        center: false,
    },
    {
        name: 'Cliente',
        selector: row => row['nomeCliente'],
        sortable: true,
        center: false,
    },
    {
        name: 'Recebimento',
        selector: row => row['situacao'] === 1 ? <span className='badge badge-light-success'>Confirmado</span> : <span className='badge badge-light-danger'>Pendente</span>,
        sortable: true,
        center: true,
   }
];

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}

const PedidoRetaguardaComponent = () => {
    const { listPedido, getPedidos, status, dataPedido, setStatus, setDataPedido   } = useContext(PedidoAppContext);    

    useEffect(() => {
      getPedidos(dataPedido, status);
    },[]);

    const handleStatus = (e) => {
      setStatus(e.target.value);
      getPedidos(dataPedido, e.target.value);
    };

    const handleData = (e) => {
      setDataPedido(e.target.value)
      getPedidos(e.target.value, status);
    };    


    return (
      <Fragment>
              <Card>
                <CardBody>

                  <FormGroup className="row">
                      <Label className="col-sm-1 col-form-label">Data</Label>                                          
                      <Col sm="2">
                        <Input id="dtPedido" formatDate ="dd/MM/YYYY" type="date" name="dataPedido" onChange={e => handleData(e)} />
                      </Col>                      
                      <Label className="col-sm-1 col-form-label">Situação</Label>                                                                  
                      <Col sm="8">
                          <Input type="select" name="select" onChange={e => handleStatus(e)} >
                            <option value="" selected>Todos</option>
                            <option value="0">Pendente</option>
                            <option value="1">Recebido</option>
                          </Input>                      
                      </Col>
                  </FormGroup>                  

                <Row>                  
                        {listPedido.map((item) => {
        return (

                <div className='prooduct-details-box'>
                  <Media>
                    
                    <div className='media-body ms-3'>
                      <div className='product-name'>
                          Data: {formatDate(item.dataPedido)}
                      </div>     
                      <br/>                                        
                      <div className='product-name'>
                        <H6>
                          <a href='3'>Pedido: {item.idPedidoDia.toString().padStart(5, '0')} {item.nomeCliente.toUpperCase()}</a>
                        </H6>
                      </div>
                      <br/>
                      <div className='price'>
                      <H6>Valor Total: {item.valorTotal.toFixed(2)}</H6>
                      </div>
                      <br/>
                      <div className='avaiabilty'>
                        {(item.situacao === 1) && <div className='text-success'>{'Recebido'}</div>}
                        {(item.situacao === 0) && <div className='text-danger'>{'Pendente'}</div>}
                      </div>
                      <br/>                      
                        <ModalEditar  
                            titulo= 'Detalhe'
                            id={item.id}
                            dataPedido={dataPedido}
                            statusPed={status}
                            size='lg'
                            />
                    </div>
                  </Media>
                </div>
         


        );
      })}
          </Row>
                </CardBody>
              </Card>

      </Fragment>
      /*
      <Fragment>
      {listPedido.map((item) => {
        return (
          <Row>
            <Col md='12'>
              <Col key={item.id} md='4'>
                <div className='prooduct-details-box'>
                  <Media>
                    <Image attrImage={{ className: 'align-self-center img-fluid img-60 border-0', alt: '#' }} />
                    <div className='media-body ms-3'>
                      <div className='product-name'>
                        <H6>
                          <a href='3'>Pedido: {item.idPedidoDia.toString().padStart(5, '0')} {item.nomeCliente.toUpperCase()}</a>
                        </H6>
                      </div>
                      <br/>
                      <div className='price'>
                      <H6>Valor Total: {item.valorTotal.toFixed(2)}</H6>
                      </div>
                      <br/>
                      <div className='avaiabilty'>
                        {(item.situacao === 1) && <div className='text-success'>{'Recebido'}</div>}
                        {(item.situacao === 0) && <div className='text-danger'>{'Pendente'}</div>}
                      </div>
                     
                        <ModalEditar  
                            titulo= 'Alterar'
                            id={item.id}
                            size='lg'
                            />
                   
                    </div>
                  </Media>
                </div>
              </Col>
            </Col>
          </Row>
        );
      })}
    </Fragment>
    */
      /*
        <Fragment>
            <DataTable
                data={listPedido}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                selectableRows
                selectableRowsSingle
                selectionMode="single"
                onSelectedRowsChange={handleRowSelected}                
                Clicked               
                noDataComponent="Não existem registros para serem exibidos."                
            />
            {(selectedRows.length !== 0 && pedidoData !== "" && pedidoData !== undefined) &&             
                <div className={`d-flex align-items-right bg-light-info p-2`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}></H4>
                    <ModalEditar  
                        titulo= 'Alterar'
                        id={selectedRows.map(r => r.id)}
                        pedidoData={pedidoData}
                        size='lg'
                        />
                </div>
            }

        </Fragment>
    */
      )
}

  function ModalEditar ({titulo, id, dtPedido, statusPed}) {
    const [modal, setModal] = useState(false);

    const { getPedido,pedidoData, editPedido, getPedidos , status,setStatus, dataPedido, setDataPedido } = useContext(PedidoAppContext);   
    

    const {
      handleSubmit,
      formState: { errors }
    } = useForm();

    const toggle = () => {
      setModal(!modal);   

      if (!modal){
        getPedido(id);
      }

    }  


    const Alterar = () => {
      if (pedidoData !== '') {
        pedidoData.situacao = 1;        
        setDataPedido(dtPedido);
        setStatus(statusPed);
        editPedido(pedidoData,pedidoData.id); 
        setModal(false);
      } else {
        errors.showMessages();
      }
    };


    const imprimir = (e) => {
      printDiv("dvcupom");
  }

  function printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
    winPrint.document.write(printContents);
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    winPrint.close(); 

}

    return (
      <Fragment>
        <Btn attrBtn={{ color: 'primary', onClick:  toggle }}>Visualizar Detalhe</Btn>
        <Modal className='modal-bookmark' isOpen={modal} toggle={toggle} size='lg'>
          <ModalHeader toggle={toggle}>Recebimento de Pedido</ModalHeader>
          <ModalBody key="idCupom">
            <Form className='form-bookmark needs-validation' onSubmit={handleSubmit(Alterar)} id="editar-form">
              <div className='form-row' id="dvcupom" style={{pageBreakBefore: 'always', top: '100%'}}>
                <FormGroup className='col-md-12'>
                <Scrollbars className="horizontal-scroll" style={{width: '100%',height:400}}>                                    
                  <Col lg='12'>
                {pedidoData.cupom?.map((txt, idx) =>
                  <Row>
                    <span style={{fontFamily: 'Courier New'}}>{txt}</span>
                  </Row>
                )}
              </Col> 

                </Scrollbars>
                </FormGroup>              
              </div>
              <Btn attrBtn={{ color: 'warning', onClick: (e) => imprimir(e)}}>Imprimir Pedido</Btn>
              {(pedidoData.situacao === 0) && <Btn attrBtn={{ color: 'success', className: 'me-2' }}>Confirmar Pagamento</Btn>}
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  };
  

export default PedidoRetaguardaComponent;