import React, { Fragment, useCallback, useEffect, useState, useContext } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../AbstractElements';
import ProdutoAppContext from '../../_helper/Produto';
import CategoriaAppContext from '../../_helper/Categoria';
import SweetAlert from 'sweetalert2';
import { Modal, ModalHeader, ModalBody, Label, Input, FormGroup, Form, Media, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import {  toast } from "react-toastify";

export const tableColumns = [
    {
        name: 'Código',
        selector: row => row['id'],
        sortable: true,
        center: false,
    },
    {
        name: 'Descrição',
        selector: row => row['descricao'],
        sortable: true,
        center: false,
    },
    {
        name: 'Ativo',
        selector: row => row['ativo'] === true ? <span className='badge badge-light-success'>Sim</span> : <span className='badge badge-light-danger'>Não</span>,
        sortable: true,
        center: true,
   }
];

const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<Col>
  <Label id="lblPesquisa">Filtro</Label>
		<Input
			id="search"
			type="text"
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
		/>
	</Col>
);


const ProdutoComponente = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);    
    const { deleteData, getList, list, produto, getProduto } = useContext(ProdutoAppContext);    

    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };
  
      return (
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
    }, [filterText, resetPaginationToggle]);    

    useEffect(() => {
        getList();
    },[]);

    const handleRowSelected  =  useCallback (state => {
        setSelectedRows(state.selectedRows);
        if (state.selectedRows.length > 0){
          getProduto(state.selectedRows[0].id);
        }
    }, []);

    const handleDelete = ()  =>  {

        SweetAlert.fire({
            text: `Deseja excluir o registro:\r ${selectedRows.map(r => r.id)}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        })
            .then(async (result) => {
                if (result.value) {
                   
                    await deleteData(selectedRows.map(r => r.id));
                    
                    setToggleDelet(!toggleDelet);

                    setSelectedRows('');

                } else {

                  toast.info("Operação Cancelada!");          
                }
            });
    };

    const filteredItems = list.filter(
      item => item.descricao && item.descricao.toLowerCase().includes(filterText.toLowerCase()),
    );    

    return (
        <Fragment>
            <ModalInserir  props={{ titulo: 'Novo', id: 0}} />
            <DataTable
                data={filteredItems}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                selectableRows
                subHeader
                subHeaderComponent={subHeaderComponentMemo}                
                selectableRowsSingle
                selectionMode="single"
                onSelectedRowsChange={handleRowSelected}                
                Clicked               
                clearSelectedRows={toggleDelet}
                noDataComponent="Não existem registros para serem exibidos."                
            />
            {(selectedRows.length !== 0 && produto !== "" && produto !== undefined) &&             
                <div className={`d-flex align-items-right bg-light-info p-2`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}></H4>
                    <ModalEditar  
                        titulo= 'Alterar'
                        id={selectedRows.map(r => r.id)}
                        dataEdit={produto}
                        />
                    {(produto.existeEstrutura === false && produto.existePedido === false) && <Btn attrBtn={{ color: 'danger', onClick: () => handleDelete() }}>Excluir</Btn>}
                </div>
            }

        </Fragment>
    )
}

function ModalInserir ({props}) {
    const [modal, setModal] = useState(false);
    const [ativo, setAtivo] = useState(true);           
    const [idCategoria, setCategoria] = useState('');        
    const { createData } = useContext(ProdutoAppContext);
    const { getCategorias, categorias, getCategoria, categoria } = useContext(CategoriaAppContext);        
 
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset
    } = useForm();
 
    const toggle = () => {

      getCategorias();

      setModal(!modal);

      setCategoria('');
      setAtivo(true);

      reset((formValues) => ({
        ...formValues,
        descricao : '',        
        id: 0,
        idCategoria: '',
        precoPequeno: '0,00',
        precoMedio: '0,00',        
        precoGrande: '0,00',
        ativo: true
      }));        
    };

    const Adicionar = (data) => {

      if (idCategoria === ''){

        SweetAlert.fire({ title: '', text: 'Selecione uma categoria', icon: 'error' });
        return;
      }

      if (data !== '') {
        data.ativo = ativo;
        data.idCategoria = idCategoria;

        if (categoria.tipoUso === "V"){
          data.precoMedio = 0;
          data.precoGrande = 0;
        }

        createData(data);
        setModal(false);
      } else {
        errors.showMessages();
      }
    };
  
      //useEffect(() => {
        //if(categorias === null || categorias === undefined || categorias.length === 0){
        //  getCategorias();
        //}
      //},[categorias]);

      const selecionarCategoria = useCallback((id) => {
        getCategoria(id);
      }, [categoria]);           
   
      const handleChangeAtivo = (e) => {
        setAtivo(e.target.checked);
      };      

      const handleChangeCategoria = (e) => {
        setCategoria('');
        setCategoria(e.target.value);
        selecionarCategoria(e.target.value);
      };                  


    return (
      <Fragment>
        <Btn attrBtn={{color: 'primary', onClick:  toggle }}>{props.titulo}</Btn>
        <Modal className='modal-bookmark' isOpen={modal} toggle={toggle} size='lg'>
          <ModalHeader toggle={toggle}>{props.titulo}</ModalHeader>
          <ModalBody>
            <Form className='form-bookmark needs-validation' onSubmit={handleSubmit(Adicionar)}>

            <div className='form-row'>
              <FormGroup className='col-md-6'>
                      <Label htmlFor="selectCategoria">Categoria</Label>
                      <Input type="select" name="selectCategoria" className="form-control digits" 
                      onChange={e => handleChangeCategoria(e)}>
                          <option key="empty" value="0">(Nenhum)</option>                                                   
                          {categorias.map((data, i) => (
                                      <option key={data.id} value={data.id}>{data.descricao}</option>
                          ))};
                      </Input>
                    </FormGroup>
              </div>

  
              <div className='form-row'>
                
                <FormGroup className='col-md-12'>
                  <Label>Descrição</Label>
                  <input className='form-control' name='descricao' type='text' {...register('descricao', { required: true })} />
                  <span style={{ color: 'red' }}>{errors.descricao && 'Conteúdo obrigatório'}</span>
                </FormGroup>              
              </div>

              {(categoria !== undefined && categoria.tipoUso === "V" && idCategoria !== '') &&     
                <div className='form-row'>
                  <FormGroup className='col-md-4'>
                    <Label>Preço</Label>
                    <input className="form-control digits" type="number" placeholder="0,00"  name='precoPequeno' {...register('precoPequeno', { required: true })} 
                        min="0.00"
                        step="0.01"
                        max="999.00"                  
                    />
                    <span style={{ color: 'red' }}>{errors.descricao && 'Conteúdo obrigatório'}</span>
                  </FormGroup>  
                </div>
              }

              {(categoria !== undefined && categoria.tipoUso !== "V" && idCategoria !== '') &&     
                <div className='form-row'>
                  <FormGroup className='col-md-4'>
                    <Label>Preço Pequeno</Label>
                    <input className="form-control digits" type="number" placeholder="0,00"  name='precoPequeno' {...register('precoPequeno', { required: true })} 
                        min="0.00"
                        step="0.01"
                        max="999.00"                  
                    />
                    <span style={{ color: 'red' }}>{errors.descricao && 'Conteúdo obrigatório'}</span>
                  </FormGroup>  

                  <FormGroup className='col-md-4'>
                    <Label>Preço Médio</Label>
                    <input className="form-control digits" type="number" placeholder="0,00"  name='precoMedio' {...register('precoMedio', { required: true })} 
                      min="0.00"
                      step="0.01"
                      max="999.00"                                    
                    />
                    <span style={{ color: 'red' }}>{errors.descricao && 'Conteúdo obrigatório'}</span>
                  </FormGroup>  

                  <FormGroup className='col-md-4'>
                    <Label>Preço Grande</Label>
                    <input className="form-control digits" type="number" placeholder="0,00"  name='precoGrande' {...register('precoGrande', { required: true })} 
                      min="0.00"
                      step="0.01"
                      max="999.00"                                    
                    />
                    <span style={{ color: 'red' }}>{errors.descricao && 'Conteúdo obrigatório'}</span>
                  </FormGroup>                

                </div>
              }

              <FormGroup className='col-md-2'>
                  <Media key="swativo">
                    <Label className='col-form-label m-r-10'>Ativo</Label>
                    <Media body className='text-end'>
                      <Label className='switch'>
                        <Input type='checkbox' {...register('ativo', { required: false })}
                        defaultChecked={ativo}
                          onChange={e => handleChangeAtivo(e)}
                        />
                        <span className='switch-state'></span>
                      </Label>
                    </Media>
                  </Media>
                </FormGroup>                                      

              <Btn attrBtn={{ color: 'primary', className: 'me-2' }}>Confirmar</Btn>
              <Btn attrBtn={{ color: 'secondary', onClick: toggle }}>Cancelar</Btn>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  };

  function ModalEditar ({titulo, id, dataEdit}) {
    const [modal, setModal] = useState(false);
    const { editData } = useContext(ProdutoAppContext);    
    const { getCategorias, categorias, categoria, getCategoria } = useContext(CategoriaAppContext);        
           
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset
    } = useForm();
  
    const toggle = () => {

      setModal(!modal);

      getCategoria(dataEdit.idCategoria);

      reset((formValues) => ({
        ...formValues,
        descricao : dataEdit.descricao,        
        id: dataEdit.id,
        idCategoria: dataEdit.idCategoria,
        precoPequeno: dataEdit.precoPequeno,
        precoMedio: dataEdit.precoMedio,        
        precoGrande: dataEdit.precoGrande,
        ativo: dataEdit.ativo
      }));        
    };

    const Alterar = (data) => {
      if (data !== '') {

          data.ativo = dataEdit.ativo;
          data.idCategoria = dataEdit.idCategoria;

        editData(data,dataEdit.id);
        setModal(false);

      } else {
        errors.showMessages();
      }
    };
  
      useEffect(() => {
        if(categorias === null || categorias === undefined || categorias.length === 0){        
          getCategorias();
        }
      },[categoria]);
   
      const handleChangeAtivo = (e) => {
        dataEdit.ativo = e.target.checked;
      };      

      const handleChangeCategoria = (e) => {
        dataEdit.idCategoria = e.target.value;        
        getCategoria(e.target.value);        
      };                  

    return (
      <Fragment>
        <Btn attrBtn={{color: 'primary', onClick:  toggle }}>Alteração</Btn>
        <Modal className='modal-bookmark' isOpen={modal} toggle={toggle} size='lg'>
          <ModalHeader toggle={toggle}>Alteração</ModalHeader>
          <ModalBody>
            <Form className='form-bookmark needs-validation' onSubmit={handleSubmit(Alterar)}>

            <div className='form-row'>
              <FormGroup className='col-md-6'>
                      <Label htmlFor="selectCaegoria">Categoria</Label>
                      <Input type="select" name="selectCategoria" className="form-control digits" 
                      defaultValue={dataEdit.idCategoria}
                      disabled={dataEdit.existePedido === true}
                      onChange={e => handleChangeCategoria(e)}>
                              {categorias.map((data, i) => (
                                          <option key={data.id} value={data.id}>{data.descricao}</option>
                              ))};
                      </Input>
                    </FormGroup>
              </div>

  
              <div className='form-row'>
                
                <FormGroup className='col-md-12'>
                  <Label>Descrição</Label>
                  <input className='form-control' name='descricao' type='text' defaultValue={dataEdit.descricao} 
                  disabled={dataEdit.existePedido === true}
                  {...register('descricao', { required: true })} />
                  <span style={{ color: 'red' }}>{errors.descricao && 'Conteúdo obrigatório'}</span>
                </FormGroup>              
              </div>

              {(categoria !== undefined && categoria.tipoUso === "V") &&   
                <div className='form-row'>
                  <FormGroup className='col-md-4'>
                    <Label>Preço Unitário</Label>
                    <input className="form-control" type="number" placeholder="0,00"  name='precoPequeno'
                    min="0.00"
                    step="0.01"
                    max="999.00"
                    presicion={2}                  
                    defaultValue={dataEdit.precoPequeno}
                    {...register('precoPequeno', { required: true })} />
                    <span style={{ color: 'red' }}>{errors.descricao && 'Conteúdo obrigatório'}</span>
                  </FormGroup>  
                </div>
              }

              {(categoria !== undefined && categoria.tipoUso !== "V") &&   
                <div className='form-row'>

                  <FormGroup className='col-md-4'>
                    <Label>Preço Pequeno</Label>
                    <input className="form-control" type="number" placeholder="0,00"  name='precoPequeno'
                    min="0.00"
                    step="0.01"
                    max="999.00"
                    presicion={2}                  
                    defaultValue={dataEdit.precoPequeno}
                    {...register('precoPequeno', { required: true })} />
                    <span style={{ color: 'red' }}>{errors.descricao && 'Conteúdo obrigatório'}</span>
                  </FormGroup>  

                  <FormGroup className='col-md-4'>
                    <Label>Preço Médio</Label>
                    <input className="form-control" type="number" placeholder="0,00"  name='precoMedio' 
                    min="0.00"
                    step="0.01"
                    max="999.00"                  
                    defaultValue={dataEdit.precoMedio}
                    {...register('precoMedio', { required: true })} />
                    <span style={{ color: 'red' }}>{errors.descricao && 'Conteúdo obrigatório'}</span>
                  </FormGroup>  

                  <FormGroup className='col-md-4'>
                    <Label>Preço Grande</Label>
                    <input className="form-control" type="number" placeholder="0,00"  name='precoGrande'
                    min="0.00"
                    step="0.01"
                    max="999.00"                  
                    defaultValue={dataEdit.precoGrande}
                    {...register('precoGrande', { required: true })} />
                    <span style={{ color: 'red' }}>{errors.descricao && 'Conteúdo obrigatório'}</span>
                  </FormGroup>                

                </div>
              }
              <FormGroup className='col-md-2'>
                  <Media key="swativo">
                    <Label className='col-form-label m-r-10'>Ativo</Label>
                    <Media body className='text-end'>
                      <Label className='switch'>
                        <Input type='checkbox' 
                        defaultChecked={dataEdit.ativo} defaultValue={dataEdit.ativo}                        
                          onChange={e => handleChangeAtivo(e)}
                        />
                        <span className='switch-state'></span>
                      </Label>
                    </Media>
                  </Media>
                </FormGroup>                                      

              <Btn attrBtn={{ color: 'primary', className: 'me-2' }}>Confirmar</Btn>
              <Btn attrBtn={{ color: 'secondary', onClick: toggle }}>Cancelar</Btn>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  };
 
  
export default ProdutoComponente
