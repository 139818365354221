import React from 'react';
import Routers from './Route';
import AuthProvider from './_helper/Auth/AuthProvider';

import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';
import UsuarioProvider from './_helper/Usuario/UsuarioProvider';
import CategoriaProvider from './_helper/Categoria/CategoriaProvider';
import ProdutoProvider from './_helper/Produto/ProdutoProvider';
import EstruturaProdutoProvider from './_helper/EstruturaProduto/EstruturaProdutoProvider';
import PedidoProvider from './_helper/Pedido/PedidoProvider';


const App = () => (
  <div className='App'>
    <CustomizerProvider>
                    
                        
                            
                                
                                  <AuthProvider>
                                  <UsuarioProvider>
                                    <ProdutoProvider>
                                    <CategoriaProvider>
                                      <EstruturaProdutoProvider>
                                        <PedidoProvider>
                                          <AnimationThemeProvider>
                                            <Routers />
                                          </AnimationThemeProvider>
                                          </PedidoProvider>
                                    </EstruturaProdutoProvider>
                                    </CategoriaProvider>
                                    </ProdutoProvider>
                                    </UsuarioProvider>
                                    </AuthProvider>
    </CustomizerProvider>
  </div>
);

export default App;
