import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Form, FormGroup, Button } from "reactstrap";
import {  H2 } from '../../AbstractElements';

const OpcaoPedidoPizza = ({ setSteps, setFormdata, formdata, pedido }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setFormdata((prev) => ({ ...prev, ...data }));
    setSteps((prev) => prev + 1);
  };

  return (
    <Fragment>
      <Row>
        <center>
          <Col sm="12">
            <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
              <FormGroup className='col-lg-3'>
                <div className="text-center btn-mb">
                  <Button className="primary"><H2>Montar minha pizza</H2></Button>
                </div>
              </FormGroup>
              <FormGroup className='col-lg-3'>            
                <div className="text-center btn-mb">
                  <Button className="primary"><H2>Escolher do cardápio</H2></Button>
                </div>            
              </FormGroup>
            </Form>
          </Col>
        </center>
      </Row>
    </Fragment>
  );
};

export default OpcaoPedidoPizza;
