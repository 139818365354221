import Context from './index';
import React, {  useState } from 'react';
import axios from 'axios';
import { PedidoApi } from '../../api';
import SweetAlert from 'sweetalert2';
import {  toast } from "react-toastify";

const PedidoProvider = (props) => {
    const [list, setList] = useState([]);
    const [data] = useState([]);
    const [pedidoData, setPedido] = useState('');    
    const [listPedido, setListPedido] = useState([]);   
    
    const [ status, setStatus  ] = useState('');    
    const [ dataPedido, setDataPedido  ] = useState('');        

    const getPedidos = (dataPedido, situacao) => {
      try {
        axios.get(`${PedidoApi}/filterPedidos?DataPedido=` + dataPedido + `&Situacao=` + situacao)
          .then((resp) => {
            setListPedido(resp.data);
          });
      } catch (error) {
          console.log('error', error);
      }
  };

  const getPedido = (id) => {
    try {
         axios.get(`${PedidoApi}/GetById/`+ id).then((resp) => {
          setPedido(resp.data);
        });
    } catch (error) {
        setPedido('');
        console.log('error', error);
    }
}; 

const editPedido = (data, id) => {

  axios.put(`${PedidoApi}/update`, data)
    .then((response) => {
      getPedidos(dataPedido, status);        
      toast.success("Pagamento Confirmado!");            
    }); 

};


    const createPedido = async (data) => {

        var formPayload = new FormData();
        formPayload.append("pedido", data);
        formPayload.append("itens", JSON.stringify(data.itens));

        await axios.post(`${PedidoApi}/add`, formPayload)
          .then((response) => {
            
            setPedido(response.data);

            if (response.data !== undefined && response.data !== "")
                {
                  let textFinal = 'Pedido Número:<h4>' + response.data.idPedidoDia.toString().padStart(5, '0') + '</h4><br/>Vamos te chamar pelo nome quando o pedido estiver pronto!<br><br>Total do Pedido <b>R$ ' + 
                  response.data.valorTotal.toFixed(2) + 
                  '</b><br><br>Efetue o pagamento no caixa!' + 
                  '<br><br><b>Bom apetite!</b>';
          
                  SweetAlert.fire({
                    title: response.data.nomeCliente,
                    html: textFinal, 
                    icon: 'success'
                  })
                      .then((result) => {
                        window.location.reload();
                      });
                }


           });        
    };

    return (
        <Context.Provider
            value={{
                ...props,
                list,
                data,
                pedidoData,
                listPedido,
                dataPedido,
                status,
                createPedido: createPedido,
                setList: setList,
                getPedidos: getPedidos,
                getPedido: getPedido,
                editPedido: editPedido,
                setStatus: setStatus,
                setDataPedido: setDataPedido
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default PedidoProvider;
