import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Form, Label, FormGroup, Button } from "reactstrap";
import {  H1, Btn } from '../../AbstractElements';
import { FontSize } from "../../Constant";

const IdentificacaoPedido = ({ setSteps, setFormdata, formdata, pedido }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setFormdata((prev) => ({ ...prev, ...data }));
    setSteps((prev) => prev + 1);
  };

  const handleNomeCliente = (e) => { 
    pedido.nomeCliente = e.target.value; 
};

  return (
    <Fragment>
      <Row>
        <center>
          <Col sm="6">
            <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
            <div style={{padding: '250px 1em 250px 1em'}}>              
                <FormGroup className="mb-3">
                  <Label htmlFor="txtnomeCliente"><H1>Informe seu nome</H1></Label>
                  <h1>
                  <input
                    required
                    className={`form-control ${errors.firstName && "is-invalid"}`} 
                    id="txtnomeCliente" 
                    type="text" 
                    name="txtnomeCliente" 
                    defaultValue={pedido.nomeCliente || ""} 
                    onChange={handleNomeCliente}  />
                  <span className="text-danger">{errors.firstName && "First name is required"}</span>
                  </h1>
                </FormGroup>
                <div className="text-center btn-mb">
                  <Btn attrBtn={{ className: "d-block w-100 mt-2", color: 'primary' }} type="submit"><H1>Continuar</H1></Btn>                
                </div>
              </div>                 
            </Form>
          </Col>
        </center>
      </Row>
    </Fragment>
  );
};

export default IdentificacaoPedido;
