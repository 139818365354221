import React, { Fragment, useState } from 'react';
import  WizardPedido  from "./WizardPedido";

const Pedido = () => {

  const pedidoData = {id: 0, idPedidoDia: 0,  nomeCliente: '', situacao: 0, valorTotal: 0.00};

  return (
<Fragment>
<WizardPedido pedido={pedidoData} />
</Fragment>
  );

};

export default Pedido;