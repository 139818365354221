import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Form, FormGroup, Label } from "reactstrap";
import {  Btn, H1 } from '../../AbstractElements';
import bg from '../../assets/images/bg_pizza2.png';
import { AlignLeft } from "react-feather";

const BemVindo = ({ setSteps, setFormdata, formdata, pedido }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setFormdata((prev) => ({ ...prev, ...data }));
    setSteps((prev) => prev + 1);
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
        <div style={{padding: '350px 0 350px 0', backgroundImage: "url(" + bg + ")",backgroundSize: '100%', margin: '0',  backgroundRepeat: 'no-repeat'  }}>
          <center>
            <Row>
              <Col lg="12">
                <FormGroup className='col-lg-4'>            
                  <div className="text-center btn-mb">
                    <Btn attrBtn={{ className: "w-200 mt-2", color: 'secondary', left: '100' }} type="submit"><H1>Iniciar Meu Pedido</H1></Btn>
                  </div>
                </FormGroup>              
              </Col>
            </Row>
          </center>
        </div>
      </Form>      
    </Fragment>
  );
};

export default BemVindo;
