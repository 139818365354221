import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import CategoriaProdutoSelecaoComponent from './CategoriaProdutoSelecaoComponent';

const CategoriaProdutoSelecao = ({ categorias, precoP, precoM, precoG }) => {

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <CategoriaProdutoSelecaoComponent categorias={categorias} 
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default CategoriaProdutoSelecao;