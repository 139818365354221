import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";



const Signout = () => {

  const history = useNavigate();  
 
  const Logout = () => {
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("Name");
    localStorage.setItem("authenticated", false);
    history(`${process.env.PUBLIC_URL}/login`);
  };  

  useEffect(() => {
    Logout();
},[]);  
 



  return (
    <></>
  );
};

export default Signout;
