import React, { Fragment, useState } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import { EmailAddress,  Password, RememberPassword, SignIn } from "../Constant";

import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

import { AuthApi } from '../api';
import axios from 'axios';

const Signin = ({ selected }) => {
  
const [email, setEmail] = useState('cliente@torrepizza.com.br');
const [password, setPassword] = useState('12345');

//const [email, setEmail] = useState('');
//const [password, setPassword] = useState('');

  const history = useNavigate();

  const loginAuth = async (e) => {

    const userTemp = {
      Email: email,
      Senha: password,
    };

    try {

        localStorage.setItem("authenticated", JSON.stringify(false));                    
        localStorage.setItem("login", JSON.stringify(false));        

        await axios.post(`${AuthApi}`, userTemp).then((resp) => {
            if (resp.data !== null)
            {
                localStorage.setItem("login", JSON.stringify(true));
                localStorage.setItem("perfil", resp.data.usuario.idPerfil);   
                
                localStorage.setItem("authenticated", JSON.stringify(true));

                toast.success("Acesso realizado com sucesso!");
                history(`${process.env.PUBLIC_URL}/dashboard/default/torrepizza`);
            }
            else
            {
              toast.error("Usuário ou senha não informado ou estão inválidos!");
            }
        });
      } catch (error) 
      {
        if  (error.response.status === 404)
        {
          toast.error("Usuário ou senha não informado ou estão inválidos!");          
        }
        console.log('error', error);
      };
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <H4>{"Acesso"}</H4>
                  <P>{"Entre com seu e-mail e senha"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <div className="position-relative">
                      <Input className="form-control" type="password" onChange={(e) => setPassword(e.target.value)} value={password} />
                    </div>
                  </FormGroup>
                  <div className="position-relative form-group mb-0">
                    <Btn attrBtn={{ color: "primary", className: "d-block w-100 mt-2", onClick: (e) => loginAuth(e) }}>{SignIn}</Btn>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;
