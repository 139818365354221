import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Card, CardBody, CardHeader, Collapse, FormGroup, Input, Label} from 'reactstrap';
import { Btn, H5 } from '../../AbstractElements';


const CategoriaProdutoSelecaoPedidoComponent = ({ categorias, objData, setVal, tamanho }) => {
  const [isOpen, setIsOpen] = useState(1);
  const toggleCarrossel = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [popover, setPopover] = useState(false);  
  const Toggle = () => setPopover(!popover);  

  const [precoP, setPrecoP] = useState(0);     
  const [precoM, setPrecoM] = useState(0);     
  const [precoG, setPrecoG] = useState(0);       
  
  const calcularCusto = () => {

    let totalPrecoP = 0;
    let totalPrecoM = 0;
    let totalPrecoG = 0;            

    setPrecoP(0);
    setPrecoM(0);
    setPrecoG(0);

    categorias.forEach(function (categoria) {
      categoria.produtos.forEach(function (produto) {
        if (produto.selecionado)
        {
          totalPrecoP += produto.precoPequeno;
          totalPrecoM += produto.precoMedio;
          totalPrecoG += produto.precoGrande;                        
        }
      });         
    });    
    
    setPrecoP(totalPrecoP.toFixed(2));
    setPrecoM(totalPrecoM.toFixed(2));
    setPrecoG(totalPrecoG.toFixed(2));

  };  

  const handleProduto = (e, idxCat, idxPrd) => {
    if (e.target.checked)
    {
      categorias[idxCat].produtos[idxPrd].selecionado = true;    
    }
    else
    {
      categorias[idxCat].produtos[idxPrd].selecionado = false;          
    }
    
    objData.ingredientes.forEach(function (ing) {
      if (ing.id == categorias[idxCat].produtos[idxPrd].id){
        ing.selecionado = categorias[idxCat].produtos[idxPrd].selecionado;
      }
    });     

    setVal(objData.id, objData.quantidadePedido, tamanho);    

  }; 

  return (
    (categorias.length !== 0 && categorias !== "") &&
    <Accordion defaultActiveKey="0" className='col-md-12'>
      <Card>
        <CardBody>
          <div className="default-according" id="accordion1">
            {categorias?.map((categoria, idxCat) =>
              (categoria.ativo === true) && <Card key={categoria.id}>
                <CardHeader className="btn-warning">
                  <H5 attrH5={{ className: 'mb-0' }} >
                    <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link text-white', color: 'default', onClick: () => toggleCarrossel(categoria.id) }} >
                      <span className="digits"><h5>{categoria.descricao}</h5></span>
                    </Btn>
                  </H5>
                </CardHeader>
                <Collapse isOpen={isOpen === categoria.id}>
                <CardBody>
                    <div className='form-row'>
                      {categoria.produtos.map((prd, idxPrd) =>                                              
                        (prd.ativo === true) && <FormGroup className='col-md-6' key={'frmGrpchkPai' + objData.id + 'Ing' + prd.id }>
                          <div className='checkbox checkbox-primary'>
                            <Input id={'chkPai' + objData.id + 'Ing' + prd.id } type="checkbox" defaultChecked={categorias[idxCat].produtos[idxPrd].selecionado}
                              onChange={e => handleProduto(e,idxCat,idxPrd)}/>
                            <Label name={'lbl' + objData.id + 'Ing' + prd.id } id={'lbl' + objData.id + 'Ing' + prd.id } htmlFor={'chkPai' + objData.id + 'Ing' + prd.id }><h6>{prd.descricao}</h6></Label>

                              <span>
                                (Pequeno: R${prd.precoPequeno.toFixed(2)} |
                                Médio: R${prd.precoMedio.toFixed(2)} |
                                Grande: R${prd.precoGrande.toFixed(2)})
                              </span>
                                                      
                          </div>
                        </FormGroup>                                              
                      )}                                                
                    </div>      
                  </CardBody>
                </Collapse>
              </Card>
            )}
          </div>
        </CardBody>
      </Card>
    </Accordion>
  );
};

export default CategoriaProdutoSelecaoPedidoComponent;