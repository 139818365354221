import React from 'react';
import { Card, CardBody, Col, Media } from 'reactstrap';
import { H4, P, Btn, Image } from '../../../AbstractElements';
import { WelcomeMessage, WelcomeToCuba, WhatsNew } from '../../../Constant';

import CarToon from '../../../assets/images/dashboard/pizza.svg';

import { useNavigate } from "react-router-dom";

const GreetingCard = () => {

  const history = useNavigate();

  const receberPedido = () => {
    history(`${process.env.PUBLIC_URL}/app/receberpedido/torrepizza`);
};


  return (
    <Col className='col-xxl-4 col-sm-6 box-col-6'>
      <Card className=' profile-box'>
        <CardBody>
          <Media>
            <Media body>
              <div className='greeting-user'>
                <H4 attrH4={{ className: 'f-w-600' }}>{WelcomeToCuba}</H4>
                <P>{WelcomeMessage}</P>
                <div className='whatsnew-btn'>
                  <Btn attrBtn={{ color: 'transparent', outline: true, className: 'btn btn-outline-white', onClick: (e) => receberPedido()  }}>{WhatsNew}</Btn>
                </div>
              </div>
            </Media>
          </Media>
          <div className='cartoon'>
            <Image attrImage={{ src: CarToon, alt: 'vector women with leptop' }} />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default GreetingCard;
