import React, { Fragment, useCallback, useEffect, useState, useContext } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../AbstractElements';
import EstruturaProdutoAppContext from '../../_helper/EstruturaProduto';
import CategoriaProdutoSelecao from '../CategoriaProdutoSelecao';
import CategoriaAppContext from '../../_helper/Categoria';
import ProdutoAppContext from '../../_helper/Produto';
import SweetAlert from 'sweetalert2';
import { Modal, ModalHeader, ModalBody, Label, Input, FormGroup, Form, Media, Col } from 'reactstrap';

import { useForm } from 'react-hook-form';
import {  toast } from "react-toastify";

export const tableColumns = [
    {
        name: 'Código',
        selector: row => row['id'],
        sortable: true,
        center: false,
    },
    {
        name: 'Descrição',
        selector: row => row.produto['descricao'],
        sortable: true,
        center: false,
    },
    {
        name: 'Ativo',
        selector: row => row['ativo'] === true ? <span className='badge badge-light-success'>Sim</span> : <span className='badge badge-light-danger'>Não</span>,
        sortable: true,
        center: true,
   }
];

const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<Col>
  <Label id="lblPesquisa">Filtro</Label>
		<Input
			id="search"
			type="text"
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
		/>
	</Col>
);

const EstruturaProdutoComponent = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);    
    const { deleteData, getList, list, model, getModel, setModel } = useContext(EstruturaProdutoAppContext);    

    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };
  
      return (
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
    }, [filterText, resetPaginationToggle]);

    useEffect(() => {
        getList();
    },[]);

    const handleRowSelected  =  useCallback (state => {
        setSelectedRows(state.selectedRows);
        if (state.selectedRows.length > 0){
          getModel(state.selectedRows[0].id);
        }
    }, [model]);

    const handleDelete = ()  =>  {

        SweetAlert.fire({
            text: `Deseja excluir o registro:\r ${selectedRows.map(r => r.id)}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        })
            .then(async (result) => {
                if (result.value) {
                   
                    await deleteData(selectedRows.map(r => r.id));
                    
                    setToggleDelet(!toggleDelet);

                    setSelectedRows('');

                    toast.success("Registro excluído com sucesso!");          

                } else {

                  toast.info("Operação Cancelada!");          
                }
            });
    };

    const filteredItems = list.filter(
      item => item.produto.descricao && item.produto.descricao.toLowerCase().includes(filterText.toLowerCase()),
    );

    return (
        <Fragment>
            <ModalInserir  props={{ titulo: 'Novo', id: 0}} />
            <DataTable
                data={filteredItems}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}                
                selectableRows
                subHeader
                subHeaderComponent={subHeaderComponentMemo}                
                selectableRowsSingle
                selectionMode="single"
                onSelectedRowsChange={handleRowSelected}                
                Clicked               
                clearSelectedRows={toggleDelet}
                noDataComponent="Não existem registros para serem exibidos."                
            />
            {(selectedRows.length !== 0 && model !== "") && 
                <div className={`d-flex align-items-right bg-light-info p-2`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}></H4>
                    <ModalEditar  
                        titulo= 'Alterar'
                        id={selectedRows.map(r => r.id)}
                        dataEdit={model}
                        />
                    <Btn attrBtn={{ color: 'danger', onClick: () => handleDelete() }}>Excluir</Btn>
                </div>
            }
        </Fragment>
    )
}

function ModalInserir ({props}) {
    const [modal, setModal] = useState(false);
    const [ativo, setAtivo] = useState(true);        
 
    const {
      handleSubmit,
      register,
      formState: { errors },
      reset
    } = useForm();
    const { createData, existeEstruturaProduto, existeProduto, idProduto,setIdProduto } = useContext(EstruturaProdutoAppContext);
    const {  list, getListTipoUso } = useContext(ProdutoAppContext);    
    const {  getCategoriasTipoUso, categorias } = useContext(CategoriaAppContext);       

    const toggle = () => {

      setModal(!modal);

      setIdProduto('0');
      setAtivo(true);

      categorias.forEach(function (categoria) {
        categoria.produtos.forEach(function (produto) {
                produto.selecionado = false;
          });
        });         


      reset((formValues) => ({
        ...formValues,
        ativo: true
      }));        
    };


    const Adicionar = (data) => {

      let categoriaValida = false;

      if (idProduto === '0') {
        SweetAlert.fire({ title: '', text: 'Selecione um produto da lista para continuar.', icon: 'error' });        
        return;
      }

      categorias.forEach(function (categoria) {
        categoria.produtos.forEach(function (produto) {
          if (produto.selecionado)
          {
            categoriaValida = true;
          }
        });         
      });    
      
      if  (!categoriaValida) {
        SweetAlert.fire({ title: '', text: 'Selecione um ou mais produtos para composição', icon: 'error' });        
        return;        
      }



      if (data !== '') {
        data.idProduto = idProduto;
        data.categorias = categorias;
        data.ativo = ativo;
        createData(data);
        setModal(false);
      } else {
        errors.showMessages();
      }
    };

    const getProdutoExistente = useCallback((id) => {
      existeEstruturaProduto(id);
    }, [existeProduto]);    

    const validarProdutoExistente = async (id) => {

      await getProdutoExistente(id);

      /*
      if (existeProduto) {
        SweetAlert.fire({ title: '', text: 'Estrutura já cadastrada para o produto selecionado.', icon: 'error' });
        setProduto('0');
        let element = document.getElementById('selectProduto');            
        element.selectedIndex = 0;
        return;        
      }
        */
    };    

    useEffect(() => {
      getListTipoUso('F');
      getCategoriasTipoUso('P');
    },[]);
  
    const handleChangeProduto = (e) => {
      setIdProduto(e.target.value);
      validarProdutoExistente(e.target.value);
    };                  

    const handleChangeAtivo = (e) => {
      setAtivo(e.target.checked);
    };  

    return (
      <Fragment>
        <Btn attrBtn={{color: 'primary', onClick:  toggle }}>{props.titulo}</Btn>
        <Modal className='modal-bookmark' isOpen={modal} toggle={toggle} size='lg'>
          <ModalHeader toggle={toggle}>{props.titulo}</ModalHeader>
          <ModalBody>
            <Form className='form-bookmark needs-validation' onSubmit={handleSubmit(Adicionar)}>
              <div className='form-row'>
                <FormGroup className='col-md-12'>
                  <Label htmlFor="selectProduto">Produto</Label>
                  <Input type="select" name="selectProduto" id="selectProduto" className="form-control digits" 
                    onChange={e => handleChangeProduto(e)}>
                      <option key="empty" value="0">(Selecione um Produto)</option>
                          {list?.map((data, i) => (
                                      <option key={data.id} value={data.id}>{data.descricao}</option>
                          ))};
                  </Input>
                </FormGroup>
              </div>

              {(categorias.length !== 0 && categorias !== "") &&
                <CategoriaProdutoSelecao 
                  categorias={categorias}  
                />
              }

                <FormGroup className='col-md-2'>
                  <Media key="swativo">
                    <Label className='col-form-label m-r-10'>Ativo</Label>
                    <Media body className='text-end'>
                      <Label className='switch'>
                        <Input type='checkbox' {...register('ativo', { required: false })}
                        defaultChecked={ativo}
                          onChange={e => handleChangeAtivo(e)}
                        />
                        <span className='switch-state'></span>
                      </Label>
                    </Media>
                  </Media>
                </FormGroup>                        

              <Btn attrBtn={{ color: 'primary', className: 'me-2' }}>Confirmar</Btn>
              <Btn attrBtn={{ color: 'secondary', onClick: toggle }}>Cancelar</Btn>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  };


  function ModalEditar ({titulo, id, dataEdit}) {
    const [modal, setModal] = useState(false);
    const [idProduto, setProduto] = useState('0');     
    const [ativo, setAtivo] = useState(true);        
 
    const {
      handleSubmit,
      register,
      formState: { errors },
      reset
    } = useForm();
    const { editData, existeEstruturaProduto, existeProduto, setExisteProduto } = useContext(EstruturaProdutoAppContext);
    const {  list, getListTipoUso } = useContext(ProdutoAppContext);    
    const {  getCategoriasTipoUso, categorias } = useContext(CategoriaAppContext);       

    const toggle = () => {

      setModal(!modal);

      setProduto(dataEdit.idProduto);
      setAtivo(dataEdit.ativo);

      reset((formValues) => ({
        ...formValues,
        ativo: dataEdit.ativo
      }));   
      
      if (modal === false){
        categorias.forEach(function (categoria) {
          categoria.produtos.forEach(function (produto) {
            if (dataEdit.produto.ingredientes !== null &&  dataEdit.produto.ingredientes !== undefined && dataEdit.produto.ingredientes.length > 0) {
              dataEdit.produto.ingredientes.forEach(function (ing) {
                if (produto.id === ing.id) {
                  produto.selecionado = true;
                }
            });         
            }
          });         
        });          
      }
    };


    const Alterar = (data) => {

      let categoriaValida = false;

      if (idProduto === '0') {
        SweetAlert.fire({ title: '', text: 'Selecione um produto da lista para continuar.', icon: 'error' });        
        return;
      }

      categorias.forEach(function (categoria) {
        categoria.produtos.forEach(function (produto) {
          if (produto.selecionado)
          {
            categoriaValida = true;
          }
        });         
      });    
      
      if  (!categoriaValida) {
        SweetAlert.fire({ title: '', text: 'Selecione um ou mais produtos para composição', icon: 'error' });        
        return;        
      }



      if (data !== '') {
        data.idProduto = idProduto;
        data.categorias = categorias;
        data.ativo = ativo;
        data.id = dataEdit.id;
        
        editData(data, dataEdit.id);

        categorias.forEach(function (categoria) {
          categoria.produtos.forEach(function (produto) {
            if (dataEdit.produto.ingredientes !== null &&  dataEdit.produto.ingredientes !== undefined && dataEdit.produto.ingredientes.length > 0) {
              dataEdit.produto.ingredientes.forEach(function (ing) {
                  produto.selecionado = false;
            });         
            }
          });         
        });  

        setModal(false);
      } else {
        errors.showMessages();
      }
    };

    const getProdutoExistente = useCallback((id) => {
      existeEstruturaProduto(id);
    }, [existeProduto]);       

    const validarProdutoExistente = async  (id) => {

      await getProdutoExistente(id);

      if (existeProduto) {
        SweetAlert.fire({ title: '', text: 'Estrutura já cadastrada para o produto selecionado.', icon: 'error' });
        setProduto('0');
        let element = document.getElementById('selectProduto');            
        element.value = "0";
        setExisteProduto(false);
        return;        
      }
    };    

    useEffect(() => {
      getListTipoUso('F');
      getCategoriasTipoUso('P');
    },[]);
  
    const handleChangeProduto = (e) => {
      setProduto(e.target.value);
      validarProdutoExistente(e.target.value);
    };                  

    const handleChangeAtivo = (e) => {
      setAtivo(e.target.checked);
    };  

    return (
      <Fragment>
        <Btn attrBtn={{color: 'primary', onClick:  toggle }}>{titulo}</Btn>
        <Modal className='modal-bookmark' isOpen={modal} toggle={toggle} size='lg'>
          <ModalHeader toggle={toggle}>{titulo}</ModalHeader>
          <ModalBody>
            <Form className='form-bookmark needs-validation' onSubmit={handleSubmit(Alterar)}>
              <div className='form-row'>
                <FormGroup className='col-md-12'>
                  <Label htmlFor="selectProduto">Produto</Label>
                  <Input type="select" name="selectProduto" id="selectProduto" className="form-control digits" 
                    defaultValue={dataEdit.idProduto}
                    disabled
                    onChange={e => handleChangeProduto(e)}>
                      <option key="empty" value="0">(Selecione um Produto)</option>
                          {list?.map((data, i) => (
                                      <option key={data.id} value={data.id}>{data.descricao}</option>
                          ))};
                  </Input>
                </FormGroup>
              </div>

              {(categorias.length !== 0 && categorias !== "") &&
                <CategoriaProdutoSelecao 
                  categorias={categorias}  
                />
              }

                <FormGroup className='col-md-2'>
                  <Media key="swativo">
                    <Label className='col-form-label m-r-10'>Ativo</Label>
                    <Media body className='text-end'>
                      <Label className='switch'>
                        <Input type='checkbox' {...register('ativo', { required: false })}
                        defaultChecked={ativo}
                          onChange={e => handleChangeAtivo(e)}
                        />
                        <span className='switch-state'></span>
                      </Label>
                    </Media>
                  </Media>
                </FormGroup>                        

              <Btn attrBtn={{ color: 'primary', className: 'me-2' }}>Confirmar</Btn>
              <Btn attrBtn={{ color: 'secondary', onClick: toggle }}>Cancelar</Btn>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  };

 
export default EstruturaProdutoComponent
