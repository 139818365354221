import Context from './index';
import React, {  useState } from 'react';
import axios from 'axios';
import { ProdutoApi } from '../../api';

import {  toast } from "react-toastify";

const ProdutoProvider = (props) => {
    const [list, setList] = useState([]);
    const [data] = useState([]);
    const [produto, setProduto] = useState([]);    
    const [listProdutoPedido, setListProdutoPedido] = useState([]);    

    const getList = () => {
        try {
             axios.get(`${ProdutoApi}/GetAll`).then((resp) => {
                setList(resp.data);
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    const getListProdutoPedido = () => {
        try {
             axios.get(`${ProdutoApi}/GetAllProdutoPedido`).then((resp) => {
                setListProdutoPedido(resp.data);
            });
        } catch (error) {
            console.log('error', error);
        }
    };    

    const getListTipoUso = (pTipouso) => {
        try {
             axios.get(`${ProdutoApi}/filterProdutos?TipoUso=` + pTipouso).then((resp) => {
                setList(resp.data);
            });
        } catch (error) {
            console.log('error', error);
        }
    };    
    
    const getProduto = (id) => {
        try {
             axios.get(`${ProdutoApi}/GetById/`+ id).then((resp) => {
                setProduto('');                
                setProduto(resp.data);
            });
        } catch (error) {
            setProduto('');            
            console.log('error', error);
        }
    };    

    const createData = (data) => {
        const produtoTemp = {
            idCategoria: data.idCategoria,
            descricao: data.descricao,                        
            precoPequeno: data.precoPequeno,            
            precoMedio: data.precoMedio,                        
            precoGrande: data.precoGrande,                        
            quantidadeEstoque: data.quantidadeEstoque,                        
            controlaSaldo: data.controlaSaldo,
            ativo: data.ativo
        };

        axios.post(`${ProdutoApi}/add`, produtoTemp)
          .then((response) => {
            getList();
            toast.success("Registro inserido com sucesso!");                        
          });        
    };


    const editData = (data, id) => {
        const produtoTemp = {
            id: id,            
            idCategoria: data.idCategoria,
            descricao: data.descricao,                                    
            precoPequeno: data.precoPequeno,            
            precoMedio: data.precoMedio,                        
            precoGrande: data.precoGrande,                        
            quantidadeEstoque: data.quantidadeEstoque,                        
            controlaSaldo: data.controlaSaldo,
            ativo: data.ativo
        };

        axios.put(`${ProdutoApi}/update`, produtoTemp)
          .then((response) => {
            getList();
            toast.success("Registro alterado com sucesso!");            
          }); 

    };

    const deleteData  = (id) => {
        axios.delete(`${ProdutoApi}/delete/` + id)
          .then((response) => {
            getList();
            toast.success("Registro excluído com sucesso!");            
          });        
    };

    return (
        <Context.Provider
            value={{
                ...props,
                list,
                data,
                produto,
                listProdutoPedido,
                createData: createData,
                editData: editData,
                deleteData: deleteData,
                getList: getList,
                setList: setList,
                getProduto: getProduto,
                setProduto: setProduto,
                getListTipoUso: getListTipoUso,
                getListProdutoPedido: getListProdutoPedido
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default ProdutoProvider;
