import Context from './index';
import React, { useState } from 'react';
import axios from 'axios';
import { AuthApi } from '../../api';

const AuthProvider = (props) => {
    const [user, setUser] = useState('');
    
    const login = async (usuario, senha) => {

        const userTemp = {
            Email: usuario,
            Senha: senha,
        };

        try {
            await axios.post(`${AuthApi}`, userTemp).then((resp) => {
                if (resp.data !== null)
                {
                    setUser(resp.data);
                }
                else
                {
                    setUser('');                
                }
            });
        } catch (error) {
            console.log('error', error);
        };
    };
    return (
        <Context.Provider
            value={{
                ...props,
                user,
                login: login
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default AuthProvider;
