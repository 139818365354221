import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import CategoriaComponent from './CategoriaComponent';

const Categorias = () => {

  return (
    <Fragment>
      <Breadcrumbs parent="Cadastros" title="Categoria" mainTitle="Listagem de Categorias" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <CategoriaComponent />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default Categorias;