export const MENUITEMS = [

  {
    menutitle: "Cadastros",
    menucontent: "Cadastros Gerais",
    Items: [
      
          { path: `${process.env.PUBLIC_URL}/app/usuario`, type: "link", title: "Usuário", icon: "user" },
          { path: `${process.env.PUBLIC_URL}/app/categoria`, type: "link", title: "Categoria", icon: "learning"  },          
          { path: `${process.env.PUBLIC_URL}/app/produto`, type: "link", title: "Produto", icon: "others"  },          
          { path: `${process.env.PUBLIC_URL}/app/estrutura`, type: "link", title: "Estrutura", icon: "social"  },//,
      ]
  },

  {
    menutitle: "Pedido",
    menucontent: "Recepção de Pedidos",
    Items: [
      { path: `${process.env.PUBLIC_URL}/app/receberpedido`, type: "link", title: "Receber", icon: "blog" },
      ]
  },  

  {
    menutitle: "Sessão",
    menucontent: "Sessão",
    Items: [
          { path: `${process.env.PUBLIC_URL}/login`, type: "link", title: "Sair", icon: "open" }
      ]
  }
];
