import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import { H3, H6 } from '../../AbstractElements';
import Scrollbars from 'react-custom-scrollbars-2';

const SubTotal = (props) => {
  return (
    <Fragment>
      <Card>      
        <CardHeader><H3>Resumo do Pedido</H3></CardHeader>        
          <CardBody>      
          <Scrollbars className="horizontal-scroll" style={{width: '100%',height:200}}>    
            <Row>
              <Col sm='10'>
                {props.produtos?.map((prd, idxCat) =>
                  <Row key={'rowQtdsp' + prd.id}>
                      {(prd.quantidadePedido > 0) &&
                      <Row key={'rowsp' + prd.id}>
                        <Col sm='6'>
                          <span id={'sp' + prd.id}>{prd.quantidadePedido}X {prd.descricao + (prd.observacao !== undefined ? prd.observacao : '')}</span>
                        </Col>
                        <Col sm='2'>
                        R$ {prd.valorTotal.toFixed(2)}
                          </Col>                    
                      </Row>
                      }
                  </Row>
                )}
              </Col>    
              <Col sm='2'>
                <center>
                    <H3>Valor Total</H3>
                    <H3>R$ {props.valorTotal.toFixed(2)}</H3>                    
                </center>
              </Col>                        
            </Row>
          </Scrollbars>                  
        </CardBody>        
      </Card>
    </Fragment>
  );
};
export default SubTotal;
