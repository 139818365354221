import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { Card, CardBody, CardHeader, Collapse, FormGroup, Input, Label } from 'reactstrap';
import { Btn,H4, H5 } from '../../AbstractElements';

const CategoriaProdutoSelecaoComponent = ({ categorias }) => {
  const [isOpen, setIsOpen] = useState(1);
  const toggleCarrossel = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

  const [precoP, setPrecoP] = useState(0);     
  const [precoM, setPrecoM] = useState(0);     
  const [precoG, setPrecoG] = useState(0);       
  
  const calcularCusto = () => {

    let totalPrecoP = 0;
    let totalPrecoM = 0;
    let totalPrecoG = 0;            

    setPrecoP(0);
    setPrecoM(0);
    setPrecoG(0);

    categorias.forEach(function (categoria) {
      categoria.produtos.forEach(function (produto) {
        if (produto.selecionado)
        {
          totalPrecoP += produto.precoPequeno;
          totalPrecoM += produto.precoMedio;
          totalPrecoG += produto.precoGrande;                        
        }
      });         
    });    
    
    setPrecoP(totalPrecoP.toFixed(2));
    setPrecoM(totalPrecoM.toFixed(2));
    setPrecoG(totalPrecoG.toFixed(2));

  };  

  useEffect(() => {
    calcularCusto();
  },[]);  

  const handleProduto = (e, idxCat, idxPrd) => {
    if (e.target.checked)
    {
      categorias[idxCat].produtos[idxPrd].selecionado = true;    
    }
    else
    {
      categorias[idxCat].produtos[idxPrd].selecionado = false;          
    }
    calcularCusto();
  }; 

  return (
    (categorias.length !== 0 && categorias !== "") &&
    <Accordion defaultActiveKey="0">
      <Card>
        <CardBody>
          <div className="default-according" id="accordion1">
            {categorias?.map((categoria, idxCat) =>
              <Card key={categoria.id}>
                <CardHeader className="btn-warning">
                  <H5 attrH5={{ className: 'mb-0' }} >
                    <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link text-white', color: 'default', onClick: () => toggleCarrossel(categoria.id) }} >
                      <span className="digits">{categoria.descricao}</span>
                    </Btn>
                  </H5>
                </CardHeader>
                <Collapse isOpen={isOpen === categoria.id}>
                  <CardBody>
                    <div className='form-row'>
                      {categoria.produtos.map((prd, idxPrd) =>                                              
                        <FormGroup className='col-md-4'>
                          <div className='checkbox checkbox-primary'>
                            <Input id={prd.id} type="checkbox" defaultChecked={categorias[idxCat].produtos[idxPrd].selecionado}
                              onChange={e => handleProduto(e,idxCat,idxPrd)}
                            />
                            <Label htmlFor={prd.id}>{prd.descricao}</Label>
                          </div>
                        </FormGroup>                                              
                      )}                                                
                    </div>      
                  </CardBody>
                </Collapse>
              </Card>
            )}
          </div>
        </CardBody>
      </Card>
      <H4>Custo estimado da composição:</H4>
      <div className='form-row'>
        <FormGroup className='col-md-4'>
          <Label>Preço Pequeno</Label>
          <input className="form-control digits" type="number" placeholder="0,00"  name='precoPequeno' disabled value={precoP} />
        </FormGroup>  
        <FormGroup className='col-md-4'>
          <Label>Preço Médio</Label>
          <input className="form-control digits" type="number" placeholder="0,00"  name='precoMedio' disabled value={precoM}/>
        </FormGroup>  
        <FormGroup className='col-md-4'>
          <Label>Preço Grande</Label>
          <input className="form-control digits" type="number" placeholder="0,00"  name='precoGrande' disabled value={precoG}/>
        </FormGroup>                
      </div>              
    </Accordion>
  );
};

export default CategoriaProdutoSelecaoComponent;